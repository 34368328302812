import React from 'react';
import LocalizedStrings from 'react-localization';
import { connect } from "react-redux";
import { hashHistory } from 'react-router';
import * as actions from "./Redux/actions"
import Grid from "@material-ui/core/Grid";
import Principal from '../PolicyAdmin/Components/Principal/Principal'
import Policies from './Components/Policies/Policies'
import Quotes from './Components/Quotes/Quotes'
import Premiums from './Components/Premiums/Premiums'
import Payments from './Components/Payments/Payments';
import { Container } from '@material-ui/core';
import PolicyHeader from './Components/PolicyHeader/PolicyHeader'
import RegisterCustomer from './Components/RegisterCustomer/RegisterCustomer'
import { POLICY_V2_PARTNERS } from '../../../lib/constants';
import { getPartnerGuid } from '../../../lib/access.es6';
import CancelPolicy from './Components/Policies/CancelPolicy';
import Claims from './Components/Claims/Claims'
import MakePayment from './Components/Payments/MakePayment';
import BoxInc from '../../../shared-ui/BoxInc/Index';
import ButtonInc from '../../../shared-ui/ButtonInc';
import {
	changeQuoteRequest, createQuoteRequest, resetChangeQuoteError, resetChangeQuoteSuccess,
	resetQuoteError, resetQuoteSuccess, setQuotationToReCreate
} from '../PolicyAdmin/Redux/actions.js'
import PolicyNotes from './Components/PolicyNotes/PolicyNotes';
import AddPolicyNotes from './Components/PolicyNotes/AddPolicyNotes';
import * as documentActions from '../../../containers/DocumentGeneration/actions.es6';
import { localisedText } from '../../../lib/localisation.es6';
import DownloadDocument from '../../../components/DownloadDocument/DownloadDocument';
import { getCustomer, updateCustomerRequest, resetUpdateCustomerl } from '../../../containers/Customer/actions.es6';
import { registerCustomerRequest, resetRegistration } from '../../../containers/CustomerScreen/components/RegisterCustomer/actions.es6';
import { addMissingPaymentRequest, addMissingPaymentReset } from '../../../containers/CustomerStatus/actions.es6';
import SmsActivity from './Components/SmsActivity/SmsActivity';
import Documents from './Components/Documents/Documents';
import { addCustomerDocumentRequest, getCustomerDocumentsRequest, setCustomerDocumentsToDelete, deleteCustomerDocumentRequest, resetDocumentToDelete, resetHardDeleteCustomerDocumentError, resetHardDeleteCustomerDocumentSuccess } from '../../../containers/CustomerFiles/actions.es6';
import UploadDocument from './Components/Documents/UploadDocument'
import BankDetails from './Components/BanksDetails/BankDetails';
import AddBankDetails from './Components/BanksDetails/AddBankDetails';
import BeneficiaryDetails from './Components/BeneficiaryDetails/BeneficiaryDetails';
import AddBenefiary from './Components/BeneficiaryDetails/AddBenefiary';
import BeneficiaryBankDetails from './Components/BeneficiaryBankDetails/BeneficiaryBankDetails';
import AddBeneficiaryBankDetails from './Components/BeneficiaryBankDetails/AddBeneficiaryBankDetails';
import {
	addCustomerBankDetailsRequest, getCustomerBankDetailsRequest,
	resetAddCustomerCustomerBankDetailsFailed, resetAddCustomerCustomerBankDetailsSuccess
} from '../../../containers/CustomerBankDetailsList/actions';
import {
	addCustomerBeneficiaryRequest, getCustomerBeneficiaryRequest,
	resetAddCustomerBeneficiaryDetailsFailed, resetAddCustomerBeneficiaryDetailsSuccess
} from '../../../containers/BeneficiaryList/actions';
import { addCustomerBeneficiaryBankDetailsRequest, getCustomerBeneficiaryBankDetailsRequest } from '../../../containers/BeneficiaryBankDetailsList/actions';
import { getCustomerQuotesRequest, getAllPoliciesRequest as getAllV2PoliciesRequest, getAllCustomerPremiumsRequest } from '../../../containers//PolicyV2CustomerStatus/Redux/actions';
import { getCustomerClaimsDcpRequest } from '../../../containers/ClaimInitiationv2/Redux/actions';
import { getAllPaymentsRequest } from '../../../containers/CustomerScreen/actions.es6';
import { reloadPage } from '../../../lib/utils.es6';
import PolicyUpgrade from './Components/PolicyUpgrade/PolicyUpgrade';
import InitiatePayment from './Components/InitiatePayment/InitiatePayment';
import DeleteDocument from './Components/Documents/DeleteDocument'
import DebitOrders from './Components/DebitOrders/DebitOrders';
import { getAllProductsRequest } from '../../../containers/NewProductList/actions.es6';
import { getDebitOrderzRequest } from '../../../containers/CustomerScreen/components/DebitOrder/state/actions';
import FailedCustomerRegistration from './FailedCustomerRegistration/index.js';


class CustomerStatusv2 extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			openRegisterCustomerDialog: false,
			openAddPaymentDialog: false,
			openPolicyNotesDialog: false,
			openUploadDocument: false,
			openAddBankDetails: false,
			openAddBeneficiary: false,
			openAddBeneficiaryBankDetails: false,
			selectedGroup: {},
			labels: new LocalizedStrings(localisedText),
			componentLoadedWithoutCustomerGuid: false,
			componentLoadedWithMsisdn: false,
			policyToUpgrade: null,
			policyForPaymentRequest: null,
			expanded: false,
		};
	}

	componentWillMount() {
		let customer_guid = this.props.params.customer_guid

		if (customer_guid) {
			this.refreshCustomerStatus(customer_guid);
		} else {
			this.setState({ componentLoadedWithoutCustomerGuid: true })
		}

		if (this.props.customerData.currentCustomer) {
			this.getAllDebitOrders();
		}

		const partnerGuid = getPartnerGuid()
		if (!POLICY_V2_PARTNERS.includes(partnerGuid)) {
			hashHistory.push('admin/home');
			return
		}
		this.props.dispatch(
			getAllProductsRequest({
				partnerGuid: partnerGuid,
			})
		);
	}

	componentDidUpdate() {
		if (this.state.componentLoadedWithoutCustomerGuid) {
			let customer_guid = this.props.params.customer_guid
			if (customer_guid) {
				this.refreshCustomerStatus(customer_guid);
				this.setState({ componentLoadedWithoutCustomerGuid: false })
			}
		}


		const customer = this.props.customerData.currentCustomer;
		const componentLoadedWithMsisdn = this.state.componentLoadedWithMsisdn;

		if (customer && !componentLoadedWithMsisdn) {
			this.refreshCustomerStatusWithMsisdn(customer.msisdn)
			this.setState({ componentLoadedWithMsisdn: true })
		}

	}


	refreshCustomerStatus(customer_guid) {
		this.props.dispatch(getCustomer(customer_guid));
		this.props.dispatch(getAllV2PoliciesRequest({ guid: customer_guid }));
		this.props.dispatch(getCustomerQuotesRequest({ guid: customer_guid }));
		this.props.dispatch(getAllCustomerPremiumsRequest({ guid: customer_guid }));
		this.props.dispatch(getAllPaymentsRequest({ guid: customer_guid }));
		this.props.dispatch(getCustomerClaimsDcpRequest({ guid: customer_guid }));
		this.props.dispatch(getCustomerDocumentsRequest(customer_guid));
		this.props.dispatch(getCustomerBankDetailsRequest(customer_guid));
		this.props.dispatch(getCustomerBeneficiaryRequest(customer_guid));
		this.props.dispatch(getCustomerBeneficiaryBankDetailsRequest(customer_guid));
		this.props.dispatch(getCustomerBeneficiaryBankDetailsRequest(customer_guid));
	}

	refreshCustomerStatusWithMsisdn(msisdn) {
		this.props.dispatch(actions.getSmsActivityRequest(msisdn));
	}


	toggleRegisterCustomerDialog() {
		this.setState({ openRegisterCustomerDialog: !this.state.openRegisterCustomerDialog })
	}
	addPayment(payment) {
		const payload = {
			paymentData: payment,
			customerGuid: this.props.customerData.currentCustomer.guid,
		};
		this.props.dispatch(addMissingPaymentRequest(payload));
	}
	resetAddPayment() {
		this.props.dispatch(addMissingPaymentReset());
	}
	registerCustomer(customerData) {
		this.props.dispatch(registerCustomerRequest(customerData));
	}
	resetRegistrationSuccess() {
		this.props.dispatch(resetRegistration());
		hashHistory.push('admin/customer_status/' + this.props.customerData.currentCustomer.guid);
		this.toggleRegisterCustomerDialog()
		reloadPage();
	}

	resetRegistrationError() {
		this.props.dispatch(resetRegistration())
	}
	editCustomer(payload) {
		this.props.dispatch(updateCustomerRequest(payload))
	}
	resetAfterSucesfulyEdit() {
		this.props.dispatch(resetUpdateCustomerl());
	}

	resetAfterFailedEdit() {
		this.props.dispatch(resetUpdateCustomerl());
	}

	cancelPolicy(policy) {
		this.props.dispatch(actions.cancelV2PolicyRequest(policy)
		);
	}
	setPolicyToCancel(policy) {
		this.props.dispatch(actions.setPolicyV2ToCancel(policy))
	}
	resetPolicyToCancel(policy) {
		this.props.dispatch(actions.resetPolicyV2ToCancel(policy))
	}
	handleMakeClaim() {
		hashHistory.push('admin/claim-initiation-v2');
	}

	toggleAddPaymentDialog() {
		this.setState({ openAddPaymentDialog: !this.state.openAddPaymentDialog, expanded: true })
	}
	handleCreateQuote() {
		hashHistory.push('admin/create-quote/'+this.props.customerData.currentCustomer.guid)
	}

	setQuotationToReCreate(policy) {
		this.props.dispatch(setQuotationToReCreate(policy))
		this.handleCreateQuote()
	}
	togglePolicyNotesDialog() {
		this.setState({ openPolicyNotesDialog: !this.state.openPolicyNotesDialog })
	}
	handlePolicyNotes(payload) {
		this.props.dispatch(actions.policyNotesRequest(payload));
	}

	setPolicyToView(policy) {
		console.log("policy set to view", policy)
		this.props.dispatch(actions.setPolicyNoteToView(policy))
	}

	handlePolicyNotesAdd(payload) {
		this.props.dispatch(actions.policyNotesAddRequest(payload));
	}

	setPolicyNoteToAdd(policy) {
		console.log("policy set to add", policy)
		this.props.dispatch(actions.setPolicyNoteToAdd(policy))
	}


	resetPolicyNoteToAdd(policy) {
		this.props.dispatch(actions.resetPolicyNoteToAdd(policy))
	}

	resetPolicyToView(policy) {
		this.props.dispatch(actions.resetPolicyNoteToView(policy))
	}

	setPolicyToDownloadDocumentFor(policy) {
		this.props.dispatch(documentActions.setDocumentEntity(policy));
		console.log("document download policy", policy)
	}

	toggleUploadDocumentDialog() {
		this.setState({ openUploadDocument: !this.state.openUploadDocument })
	}
	DocumentUpload(doc) {
		const customerGuid = this.props.customerData.currentCustomer.guid
		this.props.dispatch(addCustomerDocumentRequest({ ...doc, customerGuid }))

	}

	toggleAddBankDetailsDialog() {
		this.setState({ openAddBankDetails: !this.state.openAddBankDetails })
	}

	addBankDetails = (payload) => {
		this.props.dispatch(addCustomerBankDetailsRequest(payload));
	}

	toggleAddBeneficiaryDialog() {
		this.setState({ openAddBeneficiary: !this.state.openAddBeneficiary })
	}
	addBenefiary(payload) {
		this.props.dispatch(addCustomerBeneficiaryRequest(payload));
	}

	toggleAddBeneficiaryBankDetailsDialog() {
		this.setState({ openAddBeneficiaryBankDetails: !this.state.openAddBeneficiaryBankDetails })
	}

	addBankBeneficiaryDetails = (payload) => {
		this.props.dispatch(addCustomerBeneficiaryBankDetailsRequest(payload));
	}

	togglePolicyUpgradeDialog() {
		this.setState({ openPolicyToUpgrade: !this.state.openPolicyToUpgrade })
	}

	initiatePaymentRequest(payload) {
		this.props.dispatch(actions.initiatePaymentRequest(payload));
	}

	setDocumentToDelete(document) {
		this.props.dispatch(setCustomerDocumentsToDelete(document));
	}
	deleteDocument() {
		this.props.dispatch(deleteCustomerDocumentRequest(
			this.props.customerDocuments.documentsToDelete.guid,
		));
		console.log("docs deleted", this.props.customerDocuments.documentsToDelete.guid)
	}
	resetDocumentToDelete(document) {
		this.props.dispatch(resetDocumentToDelete(document))
	}
	getAllDebitOrders() {
		const year = new Date().getFullYear();
		const month = new Date().getUTCMonth() + 1;
		const day = new Date().getDate() + 1;
		const currentDate = `${year}-${month}-${day}`;
		const payload = {
			msisdn: this.props.customerData.currentCustomer ? this.props.customerData.currentCustomer.msisdn : '',
			status: 'Active',
			from: '2000-01-1',
			to: currentDate,
		};

		this.props.dispatch(getDebitOrderzRequest(payload));
	}
	render() {

		const templateDocuments = this.props.documentGenerationData.templateDocuments;

		if (this.props.customerData.currentCustomer) {

			return (
				<>
					<PolicyHeader openRegisterCustomerDialog={this.toggleRegisterCustomerDialog.bind(this)} />
					<RegisterCustomer
						openRegisterCustomerDialog={this.state.openRegisterCustomerDialog}
						toggleRegisterCustomerDialog={this.toggleRegisterCustomerDialog.bind(this)}
						registerCustomer={this.registerCustomer.bind(this)}
						registrationData={this.props.registrationData}
						resetRegistrationSuccess={this.resetRegistrationSuccess.bind(this)}
						resetRegistrationError={this.resetRegistrationError.bind(this)}
					/>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={3} md={3}>
							<Principal
								customerData={this.props.customerData.currentCustomer}
								showEditButton={true}
								showAutofillButton={false}
								showClaimButton={true}
								handleMakeClaim={this.handleMakeClaim.bind(this)}
								editCustomer={this.editCustomer.bind(this)}
								loader={this.props.customerData.loader}
								customerEditedSuccessfully={this.props.customerData.customer_edited_succesfully}
								customerEditedFailed={this.props.customerData.customer_edited_failed}
								resetAfterSucesfulyEdit={this.resetAfterSucesfulyEdit.bind(this)}
								resetAfterFailedEdit={this.resetAfterFailedEdit.bind(this)}
							/>
						</Grid>
						<Grid item xs={12} sm={9} md={9} style={{ marginTop: "74px" }}>
							<Container>
								<BoxInc
									title="Policies"
									expanded
									actionButtons={[<ButtonInc
										variant="contained"
										color="primary"
										size="large"
										onClick={this.handleCreateQuote.bind(this)}
									>
										Create New Quote
									</ButtonInc>]}
								>
									<Policies
										togglePolicyNotesDialog={this.togglePolicyNotesDialog.bind(this)}
										togglePolicyUpgradeDialog={this.togglePolicyUpgradeDialog.bind(this)}

										policies={this.props.customerStatusV2Data.policies}
										loading={this.props.customerStatusV2Data.get_policies_loader}
										cancelPolicy={this.cancelPolicy.bind(this)}
										setPolicyToCancel={this.setPolicyToCancel.bind(this)}
										setPolicyToView={this.setPolicyToView.bind(this)}
										setPolicyNoteToAdd={this.setPolicyNoteToAdd.bind(this)}
										setQuotationToReCreate={this.setQuotationToReCreate.bind(this)}

										templateDocuments={this.props.documentGenerationData.templateDocuments}
										setPolicyToDownloadDocumentFor={(policy) => this.setState({ policyToDownload: policy })}
										setPolicyToUpgrade={(policy) => this.setState({ policyToUpgrade: policy })}
										setPaymentInitiation={(policy) => { this.setState({ policyForPaymentRequest: policy }) }}
									/>

									<DownloadDocument
										labels={this.state.labels}
										identifier="Policies"
										entityDocument={
											this.props.documentGenerationData.entityDocument
										}
										entity={this.state.policyToDownload}
										resetEntity={() => this.setState({ policyToDownload: null })}
										templates={templateDocuments}
										getTemplates={() => this.props.dispatch(documentActions.getTemplateDocumentsRequest('Policy'))}
										getTemplatesLoader={this.props.documentGenerationData.getTemplateDocumentloader}
										isEmptyTemplatesResponse={this.props.documentGenerationData.isEmptyTemplates}
										downloadDocument={guid => {
											this.props.dispatch(
												documentActions.downloadDocumentRequest({
													entity_guid: this.state.policyToDownload.guid,
													template_guid: guid,
												})
											);
										}}
										resetGetTemplateDocuments={() => {
											this.props.dispatch(documentActions.resetGetTemplateDocuments());
										}}
										resetDownloadDocument={() => {
											this.props.dispatch(documentActions.resetDocumentEntity());
										}}
										showDownloadDocumentProgressAlert={
											this.props.documentGenerationData.downloadDocumentLoader
										}
										showDownloadDocumentSuccessAlert={
											this.props.documentGenerationData.downloadDocumentSuccess
										}
										showDownloadDocumentErrorAlert={
											!!this.props.documentGenerationData.downloadDocumentError
										}
										downloadDocumentError={
											this.props.documentGenerationData.downloadDocumentError
										}
										resetDownloadDocumentSuccessAlert={() => {
											this.props.dispatch(
												documentActions.resetDownloadDocumentSuccess()
											);
										}}
										resetDownloadDocumentErrorAlert={() => {
											this.props.dispatch(
												documentActions.resetDownloadDocumentError()
											);
										}}

										goToTemplatesPage={() => {
											hashHistory.push('admin/view_doc_templates');
										}}
									/>


									<PolicyNotes
										close={() => { this.props.dispatch(actions.resetPolicyNoteToView()) }}
										open={!!this.props.customerStatusV2Data.policyToView}
										policy={this.props.customerStatusV2Data.policyToView}
									/>

									<AddPolicyNotes
										handlePolicyNotesAdd={this.handlePolicyNotesAdd.bind(this)}
										policy={this.props.customerStatusV2Data.policyNotesToAdd}


										close={() => { this.props.dispatch(actions.resetPolicyNoteToAdd()) }}
										open={!!this.props.customerStatusV2Data.policyNotesToAdd}

										showAddPolicyNoteProgressAlert={this.props.customerStatusV2Data.policyNotesToAddLoader}
										showAddPolicyNoteSuccessAlert={this.props.customerStatusV2Data.policyNotesToAddSuccess}
										showAddPolicyNoteErrorAlert={!!this.props.customerStatusV2Data.policyNotesToAddError}
										AddPolicyNoteError={this.props.customerStatusV2Data.policyNotesToAddError}

										resetAddPolicyNoteSuccessAlert={() => {
											this.props.dispatch(actions.resetPolicyNotesAddSuccess());
											reloadPage();
										}
										}
										resetAddPolicyNoteErrorAlert={() => { this.props.dispatch(actions.resetPolicyNotesAddError()) }}

										getAllPolicies={() => { this.props.dispatch(actions.getAllPoliciesRequest({ guid: this.props.customerData.currentCustomer.guid })) }}
										resetPolicyNoteToAdd={this.resetPolicyNoteToAdd.bind(this)}

									/>

									<CancelPolicy

										handlePolicyNotes={this.handlePolicyNotes.bind(this)}

										closeCancelPolicyDialog={() => { this.props.dispatch(actions.resetPolicyV2ToCancel()) }}
										openPolicyCancelDialog={!!this.props.customerStatusV2Data.policyToCancel}

										policy={this.props.customerStatusV2Data.policyToCancel}
										cancelPolicy={this.cancelPolicy.bind(this)}
										resetPolicyToCancel={this.resetPolicyToCancel.bind(this)}

										showCancelPolicyProgressAlert={this.props.customerStatusV2Data.policyNotesLoader}
										showCancelPolicySuccessAlert={this.props.customerStatusV2Data.policyNotesSuccess}
										showCancelPolicyErrorAlert={!!this.props.customerStatusV2Data.policyNotesError}
										cancelPolicyError={this.props.customerStatusV2Data.policyNotesError}

										resetCancelPolicySuccessAlert={() => { this.props.dispatch(actions.resetPolicyNotesSuccess()) }}
										resetCancelPolicyErrorAlert={() => { this.props.dispatch(actions.resetPolicyNotesError()) }}

										getAllPolicies={() => { this.props.dispatch(actions.getAllPoliciesRequest({ guid: this.props.customerData.currentCustomer.guid })) }}
									/>

									<InitiatePayment
										policy={this.state.policyForPaymentRequest}
										resetInitiatePolicy={() => {
											this.setState({ policyForPaymentRequest: null })
											this.props.dispatch(actions.resetInitiatePayment())
										}}

										initiatePaymentRequest={this.initiatePaymentRequest.bind(this)}

										showInitiatePaymentProgressAlert={this.props.customerStatusKonyanaData.initiatePaymentLoader}
										showInitiatePaymentSuccessAlert={this.props.customerStatusKonyanaData.initiatePaymentSuccess}
										showInitiatePaymentErrorAlert={!!this.props.customerStatusKonyanaData.initiatePaymentError}
										initiatePaymentError={this.props.customerStatusKonyanaData.initiatePaymentError}
									/>

									<PolicyUpgrade
										open={this.state.openPolicyToUpgrade}
										close={this.togglePolicyUpgradeDialog.bind(this)}
										policy={this.state.policyToUpgrade}

										products={this.props.productsData.products}

										// create quote props
										createQuote={(quote) => {
											this.props.dispatch(createQuoteRequest(quote))
										}}
										createdQuote={this.props.quoteData.createdQuote}
										showAddQuoteProgressAlert={this.props.quoteData.createQuoteLoader}
										resetAddQuoteSuccessAlert={() => { this.props.dispatch(resetQuoteSuccess()) }}
										resetAddQuoteErrorAlert={() => { this.props.dispatch(resetQuoteError()) }}
										showAddQuoteErrorAlert={!!this.props.quoteData.createQuoteError}

										//change quote policy props
										changeQuote={(quote) => {
											this.props.dispatch(changeQuoteRequest(quote))
										}}
										changeQuoteProgressAlert={this.props.quoteData.changeQuoteLoader}
										changeQuoteSuccessAlert={this.props.quoteData.changeQuoteSuccess}
										changedQuote={this.props.quoteData.changeQuote}
										changeQuoteErrorAlert={!!this.props.quoteData.changeQuoteError}

										resetChangeQuoteSuccessAlert={() => { this.props.dispatch(resetChangeQuoteSuccess()) }}
										resetChangeQuoteErrorAlert={() => { this.props.dispatch(resetChangeQuoteError()) }}

										// add policy notes props
										addPolicyNote={this.handlePolicyNotesAdd.bind(this)}
										showAddPolicyNoteProgressAlert={this.props.customerStatusV2Data.policyNotesToAddLoader}
										showAddPolicyNoteSuccessAlert={this.props.customerStatusV2Data.policyNotesToAddSuccess}
										showAddPolicyNoteErrorAlert={!!this.props.customerStatusV2Data.policyNotesToAddError}
										AddPolicyNoteError={this.props.customerStatusV2Data.policyNotesToAddError}

										resetAddPolicyNoteSuccessAlert={() => {
											this.props.dispatch(actions.resetPolicyNotesAddSuccess());
											reloadPage();
										}
										}
										resetAddPolicyNoteErrorAlert={() => { this.props.dispatch(actions.resetPolicyNotesAddError()) }}

									/>
								</BoxInc>

								<BoxInc
									title="Quotes"
									actionButtons={[<ButtonInc
										variant="contained"
										color="primary"
										size="large"
										onClick={this.handleCreateQuote.bind(this)}
									>
										Create New Quote
									</ButtonInc>]}
								>
									<Quotes
										quotes={this.props.customerStatusV2Data.quotes}
										loading={this.props.customerStatusV2Data.get_quotes_loader}
									/>
								</BoxInc>

								<BoxInc
									title="Premiums"
								>
									<Premiums
										premiums={this.props.customerStatusV2Data.premiums}
										policies={this.props.customerStatusV2Data.policies}
										addPayment={this.addPayment.bind(this)}
										paymentAlert={this.props.customerStatus.success_message}
										paymentError={this.props.customerStatus.payment_error}
										resetPayment={this.resetAddPayment.bind(this)}
										loading={this.props.customerStatusV2Data.get_premiums_loader}
									/>

								</BoxInc>

								<BoxInc
									expanded={this.state.expanded}
									title="Payments"
									setExpanded={(expanded) => {
										this.setState(prevState => ({
											expanded: expanded,
										}))
									}}
									actionButtons={[<ButtonInc
										variant="contained"
										color="primary"
										size="large"
										onClick={(event) => {
											event.stopPropagation()
											this.setState(prevState => ({
												openAddPaymentDialog: true,
												expanded: true
											}))

										}}
									>
										Make Payment
									</ButtonInc>]}
								>
									<Payments
										payments={this.props.customerScreenData.payments}
										premiums={this.props.customerStatusV2Data.premiums}
										loading={this.props.customerScreenData.get_payments_loader}
										policies={this.props.customerStatusV2Data.policies}
										addPayment={this.addPayment.bind(this)}
										paymentAlert={this.props.customerStatus.success_message}
										paymentError={this.props.customerStatus.payment_error}
										resetPayment={this.resetAddPayment.bind(this)}
									/>

									<MakePayment
										policies={this.props.customerStatusV2Data.policies || []}
										openAddPaymentDialog={this.state.openAddPaymentDialog}
										handleCloseAddPaymentDialog={() => {
											this.setState({
												openAddPaymentDialog: false
											})
										}}
										addPayment={this.addPayment.bind(this)}
										paymentAlert={this.props.customerStatus.success_message}
										paymentProgressAlert={this.props.customerStatus.add_payment_loader}
										paymentError={this.props.customerStatus.payment_error}
										resetPayment={this.resetAddPayment.bind(this)}
									/>

								</BoxInc>

								<BoxInc
									title="Claims"
								>
									<Claims
										customerClaimsDcp={this.props.claimInitiationData.customer_claims_dcp}
									/>
								</BoxInc>
								<BoxInc
									title="SMS Activity"
								>
									<SmsActivity
										smses={this.props.customerStatusKonyanaData.smsActivities}
									/>
								</BoxInc>
								<BoxInc
									title="Documents"
									actionButtons={[<ButtonInc
										variant="contained"
										color="primary"
										size="large"
										onClick={this.toggleUploadDocumentDialog.bind(this)}
									>
										Upload Documents
									</ButtonInc>]}
								>
									<Documents
										documents={this.props.customerDocuments.documents}
										setDocumentToDelete={this.setDocumentToDelete.bind(this)}

									/>
									<UploadDocument
										open={this.state.openUploadDocument}
										close={this.toggleUploadDocumentDialog.bind(this)}
										DocumentUpload={this.DocumentUpload.bind(this)}
										customer_guid={this.props.customerData.currentCustomer.guid}
									/>
									<DeleteDocument
										DocumentToDelete={this.props.customerDocuments.documentsToDelete}
										deleteDocument={this.deleteDocument.bind(this)}

										resetDocumentToDelete={this.resetDocumentToDelete.bind(this)}
										showDeleteDocumentProgressAlert={this.props.customerDocuments.hardDeleteExpenseLoader}
										showDeleteDocumentSuccessAlert={this.props.customerDocuments.hardDeleteDocumentSuccess}
										showDeleteDocumentErrorAlert={!!this.props.customerDocuments.deleteDocumentError}
										deleteDocumentError={this.props.customerDocuments.deleteClaimDocumentError}

										resetDeleteDocumentSuccessAlert={() => {
											this.props.dispatch(resetHardDeleteCustomerDocumentSuccess())
										}}
										resetDeleteDocumentErrorAlert={() => { this.props.dispatch(resetHardDeleteCustomerDocumentError()) }}
									/>
								</BoxInc>

								<BoxInc
									title="Bank Details"
								>
									<BankDetails
										banksDetails={this.props.customerbankDetailsData.bankdetail ? this.props.customerbankDetailsData.bankdetail : []}
										loading={this.props.customerbankDetailsData.loader}
										customerGuid={this.props.customerData.currentCustomer.guid}
									/>
									<AddBankDetails
										open={this.state.openAddBankDetails}
										close={this.toggleAddBankDetailsDialog.bind(this)}
										customerGuid={this.props.customerData.currentCustomer.guid}
										addBankDetails={this.addBankDetails.bind(this)}


										showAddBankDetailsProgressAlert={this.props.customerbankDetailsData.createbankdetailLoader}
										showAddBankDetailsSuccessAlert={this.props.customerbankDetailsData.createbankdetailSuccess}
										showAddBankDetailsErrorAlert={!!this.props.customerbankDetailsData.createbankdetailError}
										error={this.props.customerbankDetailsData.createbankdetailError}

										resetAddBankDetailsSuccessAlert={() => { this.props.dispatch(resetAddCustomerCustomerBankDetailsSuccess()) }}
										resetAddBankDetailsErrorAlert={() => { this.props.dispatch(resetAddCustomerCustomerBankDetailsFailed()) }}
									/>
								</BoxInc>

								<BoxInc
									title="Beneficiary Details"
								>
									<BeneficiaryDetails
										beneficiary={this.props.customerBeneficiary.beneficiary ? this.props.customerBeneficiary.beneficiary : []}
										customerGuid={this.props.customerData.currentCustomer.guid}
									//loading={this.props.customerbankDetailsData.loader}
									/>
									<AddBenefiary
										open={this.state.openAddBeneficiary}
										close={this.toggleAddBeneficiaryDialog.bind(this)}
										customerGuid={this.props.customerData.currentCustomer.guid}
										addBenefiary={this.addBenefiary.bind(this)}

										showAddBeneficiaryProgressAlert={this.props.customerBeneficiary.createbeneficiaryLoader}
										showAddBeneficiarySuccessAlert={this.props.customerBeneficiary.createbeneficiarySuccess}
										showAddBeneficiaryErrorAlert={!!this.props.customerBeneficiary.createbeneficiaryError}
										error={this.props.customerBeneficiary.createbeneficiaryError}
										resetAddBeneficiarySuccessAlert={() => { this.props.dispatch(resetAddCustomerBeneficiaryDetailsSuccess()) }}
										resetAddBenefeciaryErrorAlert={() => { this.props.dispatch(resetAddCustomerBeneficiaryDetailsFailed()) }}
									/>
								</BoxInc>

								<BoxInc
									title="Beneficiary Bank Details"
								>
									{
										this.props.customerBeneficiary.beneficiary ? (	<BeneficiaryBankDetails
											banksDetails={this.props.customerBeneficiaryBankDetailsData.bankdetail ? this.props.customerBeneficiaryBankDetailsData.bankdetail : []}
											loading={this.props.customerbankDetailsData.loader}
											beneficiaryGuid={this.props.customerBeneficiary.beneficiary ? this.props.customerBeneficiary.beneficiary.guid : ""}
											customerGuid={this.props.customerData.currentCustomer.guid}
	
										/>) : "Please add beneficiary details before adding beneficiary bank details"
									}
								
									<AddBeneficiaryBankDetails
										open={this.state.openAddBeneficiaryBankDetails}
										close={this.toggleAddBeneficiaryBankDetailsDialog.bind(this)}
										customerGuid={this.props.customerData.currentCustomer.guid}
										//beneficiaryGuid={this.props.customerBeneficiary.beneficiary.giud}
										addBankBeneficiaryDetails={this.addBankBeneficiaryDetails.bind(this)}

									/>
								</BoxInc>


								<BoxInc
									title="Debit Orders"
								>
									{
										this.props.customerStatusV2Data.policies && this.props.customerStatusV2Data.policies.length > 0 ? (<DebitOrders
											policy={this.props.customerStatusV2Data.policies}
											msisdn={this.props.customerData.currentCustomer.msisdn}
											debitOrders={this.props.newDebitOrderData.debitOrders}
										/>) : "Generate policy to update the debit orders"
									}


								</BoxInc>

							</Container>
						</Grid>

					</Grid>

				</>
			)
		} 
		else if (this.props.customerData.customer_refresh_failed === true) {
			return <FailedCustomerRegistration
				guid={this.props.params.customer_guid}

			/>
		} else {
			return (
				<>
					<PolicyHeader openRegisterCustomerDialog={this.toggleRegisterCustomerDialog.bind(this)} />
					<RegisterCustomer
						openRegisterCustomerDialog={this.state.openRegisterCustomerDialog}
						toggleRegisterCustomerDialog={this.toggleRegisterCustomerDialog.bind(this)}
						registerCustomer={this.registerCustomer.bind(this)}
						registrationData={this.props.registrationData}
						resetRegistrationSuccess={this.resetRegistrationSuccess.bind(this)}
						resetRegistrationError={this.resetRegistrationError.bind(this)}
					/>
				</>
			)
		}
	}
}
export default connect((state) => ({

	customerDocuments: state.customerDocuments,
	claimInitiationData: state.claimInitiationData,
	customerStatusV2Data: state.customerStatusV2Data,
	customerStatus: state.customerStatus,
	customerScreenData: state.customerScreen,
	quoteData: state.quoteData,
	customerData: state.currentCustomer,
	registrationData: state.customerRegistration,
	customerBeneficiary: state.customerBeneficiary,
	customerbankDetailsData: state.customerbankdetails,
	customerBeneficiaryBankDetailsData: state.customerBeneficiarybankdetails,
	documentGenerationData: state.documentGeneration,
	globalData: state.global,
	customerStatusKonyanaData: state.customerStatusKonyanaState,
	productsData: state.newProducts,
	newDebitOrderData: state.newDebitOrder
}))(CustomerStatusv2);