import React from 'react'
import { useStyle } from "./Style";

const ClaimSummary = (props) => {
  if (!props.claimInitiationPayload) {
    return null
  } else {

  }
  const classes = useStyle();

  return (
    <div>

      <div>
        {/* Hospital Claim Type */}
        {
          props.claimInitiationPayload.type === "Hospital" && (
            <div className={classes.summaryWrapper}>
              <p><strong>Type: </strong>{props.claimInitiationPayload.type}</p>
              <p><strong>Relation to main member: </strong>{props.claimInitiationPayload.relation_to_main_member}</p>
              <p><strong>Hospital Admission Date: </strong>{props.claimInitiationPayload.hospital_admission_date}</p>
              <p><strong>Hospital Discharge Date: </strong>{props.claimInitiationPayload.hospital_discharge_date}</p>
            </div>
          )
        }

        {/* Death Claim Type */}
        {
          props.claimInitiationPayload.type === "Death" && (
            <div className={classes.summaryWrapper}>
              <p><strong>Type: </strong>{props.claimInitiationPayload.type}</p>
              <p><strong>Relation to main member: </strong>{props.claimInitiationPayload.relation_to_main_member}</p>
              <p><strong>Date of Death: </strong>{props.claimInitiationPayload.death_date}</p>
            </div>
          )
        }

        {/* Personal Accident Claim Type */}
        {
          props.claimInitiationPayload.type === "Personal_Accident" && (
            <div className={classes.summaryWrapper}>
              <p><strong>Type: </strong>{props.claimInitiationPayload.type}</p>
              <p><strong>Relation to main member: </strong>{props.claimInitiationPayload.relation_to_main_member}</p>
              <p><strong>Date of Event: </strong>{props.claimInitiationPayload.event_date}</p>
            </div>
          )
        }
      </div>


    </div>
  )
}

export default ClaimSummary