import React from "react";
import { connect } from "react-redux";
import moment from 'moment';
import ClaimsHeader from "./Components/ClaimsHeader/ClaimsHeader";
import Grid from "@material-ui/core/Grid";
import Principal from "../../../containers/Policyv2/Components/Principal/Principal";
import ClaimInitiationWrapper from "./Components/ClaimInitiationWrapper/ClaimInitiationWrapper";
import { getCustomer } from "../../../containers/Customer/actions.es6";
import *  as actions from "./Redux/actions"
import SelectPolicy from "./Components/SelectPolicy/SelectPolicy";
import SelectMember from "./Components/SelectMember/SelectMember";
import ClaimLoggingDetails from "./Components/ClaimLoggingDetails/ClaimLoggingDetails";
import ClaimDetails from "./Components/ClaimDetails/ClaimDetails";
import { getUserFullname, getUserGuid } from "../../../lib/access.es6";
import AdditionalDetails from "./Components/AdditionalDetails/AdditionalDetails";
import find from 'lodash/find';
import { getAllPoliciesRequest } from "../CustomerStatus/Redux/actions";

class ClaimInitiation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      claimInitiationPayload: {
        "event_date": null,
        "policy_guid": null,
        "policy_number": null,
        "quotation_guid": null,
        "customer_guid": null,
        "dependant_guid": null,
        "partner": null,
        "benefits": [],
        "initiator_fullname": null,
        "initiator_msisdn": null,
        "web_agent_guid": null,
        "web_agent_name": null,
        "additional_details": null,
      },

      "claimant_phone_number": '',
      "claimant_full_name": '',
      "claimant_id_number": '',
      "claimant_relationship_to_patient": '',
      "admission_date": '',
      "discharge_date": '',
      "number_of_days_in_hospital": '',
      "date_of_death": '',
      "funeral_home_name": '',
      "death_certficate_number": '',
      "device_number": '',
      "date_of_damage": '',
      "date_of_disability": '',
      "date_of_theft": '',
      "installment_amount": '',
      age: '',
    };
  }

  setAdditionalState(name, value){
    this.setState({[name]: value})
  }

  componentWillMount() {


    let customer_guid = this.props.params.customer_guid
    if (customer_guid) {
      this.refreshCustomerDetails(customer_guid)
      console.log("customer request")
    }
    if(customer_guid){
      this.props.dispatch(getAllPoliciesRequest({ guid: customer_guid }))
    }


    if (customer_guid) {
      this.props.dispatch(actions.getCustomerClaimsDcpRequest({
        guid: customer_guid
      })
      );
    }


  }

  refreshCustomerDetails(customer_guid) {
    this.props.dispatch(getCustomer(customer_guid))

	}

   getAge(){
    const member = this.state.claimInitiationPayload.member ? this.state.claimInitiationPayload.member.dependant : null

    if (member) {

      const dob = member.date_of_birth;
      const currentDate = moment();
      const birthDate = moment(dob);
      const currentAge = currentDate.diff(birthDate, 'years');
      return currentAge;
    }
    return ""
  };

  handleSelectPolicies(payload) {
    this.props.dispatch(actions.getEventQuoteRequest(payload));
  }

  handleInitiateClaim() {
    let payload = this.state.claimInitiationPayload;
    payload['policy_guid'] = this.props.claimInitiationData.eventQuote.policy_guid;
    payload['policy_number'] = this.state.claimInitiationPayload.policy_number;
    payload['quotation_guid'] = this.props.claimInitiationData.eventQuote.guid;
    payload['customer_guid'] = this.props.claimInitiationData.eventQuote.customer_guid;
    payload['partner'] = this.props.claimInitiationData.eventQuote.partner;
    payload['web_agent_guid'] = getUserGuid();
    payload['web_agent_name'] = getUserFullname();

    payload = {
      ...payload,
      policy_number2: this.state.claimInitiationPayload.policy_number,
      initiator_fullname: this.state.claimant_full_name,
      initiator_msisdn: this.state.claimant_phone_number,
      additional_details: {
        ...payload.additional_details,
        admission_date: this.state.admission_date,
        discharge_date: this.state.discharge_date,
        number_of_days_in_hospital: this.state.number_of_days_in_hospital,
        date_of_death: this.state.claimInitiationPayload.event_date,
        funeral_home_name: this.state.funeral_home_name,
        death_certficate_number: this.state.death_certficate_number,
        date_of_damage: this.state.claimInitiationPayload.event_date,
        date_of_disability: this.state.claimInitiationPayload.event_date,
        claimant_phone_number: this.state.claimant_phone_number,
        claimant_full_name: this.state.claimant_full_name,
        claimant_id_number: this.state.claimant_id_number,
        claimant_relationship_to_patient: this.state.claimant_relationship_to_patient,
        age: this.getAge(),
      }
    }

    console.log('payload: ', payload);
    this.props.dispatch(actions.initiateClaimRequest(payload));
  }

  render() {

    if (!this.props.customerData.currentCustomer) {
      return "Loading...";
  }

   const hasDeathBenefit = !!find(this.state.claimInitiationPayload.benefits, {type: "Death"})
   const hasFuneralBenefit = !!find(this.state.claimInitiationPayload.benefits, {type: "Funeral"})
   const hasDeviceDamageBenefit = !!find(this.state.claimInitiationPayload.benefits, {type: "Device_Damage"})
   const hasParmanentDisabilityBenefit = !!find(this.state.claimInitiationPayload.benefits, {type: "Permanent_Disability"})
   const hasDeviceTheftBenefit = !!find(this.state.claimInitiationPayload.benefits, {type: "Device_Theft"})
   const hasHospitalBenefit = !!find(this.state.claimInitiationPayload.benefits, {type: "Hospital"})
   const hasTemporaryDisabilityBenefit = !!find(this.state.claimInitiationPayload.benefits, {type: "Temporary_Disability"})




    let agentUsersData = this.props.userData.users.filter(user => user.attributes.isAgent && user.attributes.isAgent[0] === 'true') || [];
    return (
      <>
        <ClaimsHeader
          title="Initiate Claim"
        />
        <Grid container spacing={2}>
          <Grid xs={12} md={3} sm={3}>
            <Principal
              customerData={this.props.customerData.currentCustomer}
              loader={false}
            />
          </Grid>
          <ClaimInitiationWrapper
            policies={this.props.customerStatusV2Data.policies}
            handleSelectPolicies={this.handleSelectPolicies.bind(this)}
            selectedMember={this.props.claimInitiationData.selectedPolicy}
            eventQuote={this.props.claimInitiationData.eventQuote}
            customerClaimsDcp={this.props.claimInitiationData.customer_claims_dcp}
            handleInitiateClaim={this.handleInitiateClaim.bind(this)}
            hasDeathBenefit={hasDeathBenefit}
            createdClaim={this.props.claimInitiationData.initiateClaim}
            components={
              {
                SelectPolicy: <SelectPolicy
                  policies={this.props.customerStatusV2Data.policies}
                  customerData={this.props.customerData.currentCustomer}
                  handleSelectPolicies={this.handleSelectPolicies.bind(this)}

                  eventQuoteSuccess={this.props.claimInitiationData.eventQuoteSuccess}
                  eventQuoteLoader={this.props.claimInitiationData.eventQuoteLoader}
                  eventQuoteError={this.props.claimInitiationData.eventQuoteError}
                  eventQuote={this.props.claimInitiationData.eventQuote}


                  claimInitiationPayload={this.state.claimInitiationPayload}
                  setClaimInitiationPayload={(newClaimInitiationPayload) => this.setState({ claimInitiationPayload: newClaimInitiationPayload })}
                />,
                SelectMember:
                  <SelectMember
                    eventQuote={this.props.claimInitiationData.eventQuote}
                    claimInitiationPayload={this.state.claimInitiationPayload}
                    setClaimInitiationPayload={(newClaimInitiationPayload) => this.setState({ claimInitiationPayload: newClaimInitiationPayload })}
                  />,

                AdditionalDetails: <AdditionalDetails

                  hasDeathBenefit={hasDeathBenefit}
                  hasHospitalBenefit={hasHospitalBenefit}
                  hasDeviceDamageBenefit={hasDeviceDamageBenefit}
                  hasDeviceTheftBenefit={hasDeviceTheftBenefit}
                  hasParmanentDisabilityBenefit={hasParmanentDisabilityBenefit}
                  hasFuneralBenefit={hasFuneralBenefit}
                  hasTemporaryDisabilityBenefit={hasTemporaryDisabilityBenefit}

                  handleInitiateClaim={this.handleInitiateClaim.bind(this)}
                  eventQuote={this.props.claimInitiationData.eventQuote}

                  claimInitiationPayload={this.state.claimInitiationPayload}
                  setClaimInitiationPayload={(newClaimInitiationPayload) => this.setState({ claimInitiationPayload: newClaimInitiationPayload })}
                  member={this.state.claimInitiationPayload.member ? this.state.claimInitiationPayload.member.dependant : null}
                  eventDate={this.state.claimInitiationPayload.event_date}
                  setAdditionalState={this.setAdditionalState.bind(this)}

                  eventData={this.props.claimInitiationData.eventQuote}
                />,


                ClaimLoggingDetails: <ClaimLoggingDetails
                  handleInitiateClaim={this.handleInitiateClaim.bind(this)}
                  eventQuote={this.props.claimInitiationData.eventQuote}

                  claimInitiationPayload={this.state.claimInitiationPayload}
                  setClaimInitiationPayload={(newClaimInitiationPayload) => this.setState({ claimInitiationPayload: newClaimInitiationPayload })}
                  hasDeathBenefit={hasDeathBenefit}
                  setAdditionalState={this.setAdditionalState.bind(this)}
                  agentUsersData={agentUsersData}
                />,
                ClaimDetails: <ClaimDetails
                  claimDetailsData={this.props.claimInitiationData.initiateClaim}
                  initiateClaimLoader={this.props.claimInitiationData.initiateClaimLoader}
                />
              }
            }
          />


        </Grid>
      </>
    )
  }

}

export default connect((state) => ({
  customerData: state.currentCustomer,
  claimInitiationData: state.claimInitiationData,
  customerStatusV2Data: state.customerStatusV2Data,
  userData: state.userAdmin,
  globalData: state.global,
}))(ClaimInitiation);