import { take, call, put } from "redux-saga/effects";
import * as types from "../../../lib/actionTypes.es6";

import * as actions from "./actions";

import { getAudits } from "../../../lib/requests.es6";

import { GAloggerException } from "../../../lib/monitoring.es6";
import { unAuthorizedNotification } from "../../../lib/access.es6";

export function* getAuditsRequestWatcher() {
  for (;;) {
    const request = yield take(types.GET_AUDITS_REQUEST);
    try {
      const response = yield call(getAudits, request.payload);
      yield put(actions.getAuditsSuccess(response.data));
    } catch (e) {
      let errorMessage = "An unexpected error occurred";
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.getAuditsFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}
