import React from 'react';
import './SplashScreen.css'; 
import SpashLogo from '../.././Assets/512x512.png'

const SplashScreen = () => {
  return (
    <div className="splash-screen">
      <div className="logo-container">
        <img src={SpashLogo} alt="App Logo" className="logo-animation" />
      </div>
      <h1 className="loading-text" style={{
         fontFamily: "Open Sans",
         fontStyle: "normal",
         fontWeight: 600,
         fontSize: "26px",
         lineHeight: "15px",
         color: "#ffffff",
      }}>Loading...</h1>
    </div>
  );
};

export default SplashScreen;
