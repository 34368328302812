import React from 'react'
import { Box, Grid, Button } from '@mui/material';
import { hashHistory } from 'react-router';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import { useStyle } from "./Style";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import { getPartner, getPartnerGuid } from '../../lib/access.es6';
import { formatDateLocale } from '../../lib/utils.es6';
import SkeletonWrapper from '../../containers/NewProductList/components/SkeletonWrapper/SkeletonWrapper';

import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import ViewPackageDetailsInc from '../ViewPackageDetailsInc';
const ITEM_HEIGHT = 48;



const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiDialog-paper': {
    width: "100%",
    maxWidth: "1000px"
  }
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: '#fff',
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};


const PoliciesCardInc = (props) => {

  // Convert data to array if it's an object, or use it directly if it's already an array
  const policies = Array.isArray(props.data) ? props.data : (props.data ? [props.data] : []);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedPolicyAction, setSelectedPolicyAction] = React.useState(null)

  const [openViewPackage, setOpenViewPackage] = React.useState(false);
  const [selectedPolicyPackage, setSelectedPolicyPackage] = React.useState(null)


  const open = Boolean(anchorEl);

  const handleClick = (event, policies) => {
    setAnchorEl(event.currentTarget);
    setSelectedPolicyAction(policies);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  const handleClickOpenViewPackage = (policy) => {
    setSelectedPolicyPackage(policy);
    setOpenViewPackage(true);
  };

  const handleCloseViewPackage = () => {
    setAnchorEl(null);
    setOpenViewPackage(false);
  };

  const [expanded, setExpanded] = React.useState(false);

  const classes = useStyle();




  const dataForDisplay = expanded ? policies : policies.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)).slice(0, 2)


  const currencyCode = getPartner().country.currency_code

  const viewPolicy = (policy_number) => {
    hashHistory.push(`admin/policy-processing/${policy_number}`);
  };



  const shouldHideViewDetails = () => {
    const currentUrl = window.location.href;
    // Check if the current URL matches the pattern
    return currentUrl.includes("/admin/policy-processing/");
  }

  const shouldShowActionsItems = () => {
    const currentUrl = window.location.href;
    return currentUrl.includes("/admin/policy-processing/");
  }


  if (props.loader) {
    return <SkeletonWrapper loading={props.loader} />;
  }


  if (dataForDisplay.length > 0) {

    return (
      <>
        {
          dataForDisplay.map((policy, index) => (

            <Grid container className={classes.policyWrapper} key={index}>
              <Grid item xs={12} sm={12} md={12}>
                <Grid container className={classes.policyHeader}>
                  <Grid item xs={12} sm={6} md={6}>
                    <Grid container>
                      <Grid item xs={12} md={2} sm={2} className={classes.policyIconWrapper}   >
                        <Box className={classes.policyIcons}>
                          <NoteAddOutlinedIcon fontSize="14px" sx={{ color: "#ffffff" }} />
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={10} sm={10} className={classes.policyHeaderContent}>
                        <h6>{policy.policy_number || "N/A"}</h6>
                        <p> Policy Number</p>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} >

                    <Grid container>
                      <Grid item xs={12} md={4} sm={4}>
                        <div >

                        </div>
                      </Grid>
                      <Grid item xs={12} md={8} sm={8} className={classes.policyActiveStatusWrapper}>
                        <Box display="flex" justifyContent="flex-end">
                          <ul>
                            <li className={policy.active === true ? classes.activePill : classes.inactivePill}> <span>{policy.active === true ? `Active` : `Inactive`}</span></li>
                            {
                              shouldShowActionsItems() && (
                                <li className={classes.actionbtnpolicy}>
                                  <IconButton
                                    aria-label="more"
                                    id="long-button"
                                    aria-controls={open ? 'long-menu' : undefined}
                                    aria-expanded={open ? 'true' : undefined}
                                    aria-haspopup="true"
                                    onClick={(event) => handleClick(event, policies)}
                                  >
                                    <MoreVertIcon />
                                  </IconButton>
                                  {
                                    selectedPolicyAction && selectedPolicyAction.guid === policies.guid && <Menu
                                      id="long-menu"
                                      MenuListProps={{
                                        'aria-labelledby': 'long-button',
                                      }}
                                      anchorEl={anchorEl}
                                      open={open}
                                      onClose={handleClose}
                                      PaperProps={{
                                        style: {
                                          maxHeight: ITEM_HEIGHT * 4.5,
                                          width: '20ch',
                                        },
                                      }}
                                    >
                                      {
                                        getPartnerGuid() === 'ngsugguid' && <MenuItem style={{
                                          cursor: "pointer", fontFamily: "Open Sans",
                                          fontStyle: "normal",
                                          fontWeight: 400,
                                          fontSize: "12px",
                                          lineHeight: "15px",
                                          color: "#000000",
                                        }} onClick={() => { setAnchorEl(null); handleClickOpenViewPackage(policy) }} >View Package Details</MenuItem>
                                      }

                                    </Menu>
                                  }

                                </li>
                              )
                            }

                            {!shouldHideViewDetails() && (
                              <li onClick={() => viewPolicy(policy.policy_number)}>
                                View Details <span><ChevronRightOutlinedIcon /></span>
                              </li>)}
                          </ul>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  <div className={classes.policyHeaderBorder}></div>
                </Grid>

                <Grid container spacing={2} className={classes.policyContentWrapper}>
                  <Grid item xs={12} sm={3} md={3} >
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={3} md={3}></Grid>
                      <Grid item xs={12} sm={9} md={9}>
                        <p>Plan </p>
                        <h6> {policy.product_name || "N/A"}</h6>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3}>
                    <p> Start Date</p>
                    <h6>{formatDateLocale(policy.start_date) || "N/A"}
                    </h6>

                  </Grid>
                  {
                    getPartnerGuid() === 'realpeopleguid' ? (<Grid item xs={12} sm={3} md={3}>
                      <p>End Date</p>
                      <h6>{formatDateLocale(policy.end_date) || "N/A"}</h6>
                    </Grid>) : (<Grid item xs={12} sm={3} md={3}>
                      <p>Due Date</p>
                      <h6>{formatDateLocale(policy.due_date) || "N/A"}</h6>
                    </Grid>)
                  }

                  <Grid item xs={12} sm={3} md={3}>
                    <p>Premiums</p>
                    <h6>{
                      (policy.quotation && policy.quotation.total_premium_amount_in_cents)
                        ? `${currencyCode}  ${policy.quotation.total_premium_amount_in_cents / 100} per ${policy.quotation.premium_cardinality} ${policy.quotation.premium_granularity}` || "0.00"

                        : "N/A"
                    }</h6>
                  </Grid>

                </Grid>
                <div className={classes.policyContentBorder}></div>

                <Grid container spacing={2} className={classes.policyFooterWrapper}>
                  <Grid item xs={12} md={6} sm={6}>
                    <Grid container spacing={2} >


                      <Grid item xs={12} md={4} sm={4}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={3} md={3}></Grid>
                          <Grid item xs={12} sm={9} md={9}>
                            <p>Beneficiary Name</p>
                            <h6>{policy.beneficiary_name || "N/A"}</h6>

                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={4} sm={4}>
                        <p> Beneficiary Phone Number</p>
                        <h6>{policy.beneficiary_msisdn || "N/A"}</h6>
                      </Grid>

                      <Grid item xs={12} md={4} sm={4}>
                        <p> Created At</p>
                        <h6>{formatDateLocale(policy.created_at) || "N/A"}</h6>
                      </Grid>

                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6} sm={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={6}>

                      </Grid>
                      <Grid container xs={12} sm={6} md={6}>
                        {
                          policy.additional_details.branch ? (<>
                            <Grid item xs={12} sm={4} md={4}>
                              <div className={classes.policyBalance}>
                                <p style={{
                                  textAlign: "center"
                                }}>Branch</p>
                                <h6 style={{
                                  textAlign: "center"
                                }}>{policy.additional_details.branch || "N/A"}</h6>
                              </div>
                            </Grid>

                          </>) : (<>
                            <Grid item xs={12} sm={4} md={4}>

                            </Grid>

                          </>)
                        }

                        <Grid item xs={12} sm={4} md={4}>
                          <div className={classes.policyBalance}>
                            <p style={{
                              textAlign: "center"
                            }}>status</p>
                            <h6 style={{
                              textAlign: "center"
                            }}>{policy.status || "N/A"}</h6>
                          </div>
                        </Grid>
                        {
                          getPartnerGuid() === 'realpeopleguid' ? (<Grid item xs={12} sm={4} md={4}></Grid>) : (<>
                            <Grid item xs={12} sm={4} md={4}>
                              <div className={classes.policyBalance}>
                                <p >Balance</p>
                                <h6>{`${currencyCode}  ${policy.balance_amount_in_cents / 100}` || "0.00"}    </h6>
                              </div>
                            </Grid>
                          </>)
                        }

                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ))
        }
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            {
              dataForDisplay.length > 1 && (
                <Button style={{
                  background: "#FBA92D",
                  borderRadius: "8px",
                  fontFamily: "Open Sans",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "12px",
                  lineHeight: "15px",
                  boxShadow: "none",
                  padding: "10px",
                  marginTop: "20px",
                  textTransform: "capitalize",
                }} variant="contained" size="large" endIcon={expanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />} onClick={() => setExpanded(!expanded)}>
                  {expanded ? `Show Less` : `Show More`}
                </Button>
              )
            }


          </Grid>
        </Grid>

        {
          getPartnerGuid() === 'ngsugguid' && <BootstrapDialog
            onClose={handleCloseViewPackage}
            aria-labelledby="dependants-dialog-title"
            open={openViewPackage}
          >
            <BootstrapDialogTitle className={classes.dependantstitle} id="dependants-dialog-title" onClose={handleCloseViewPackage}>
              Package Details
            </BootstrapDialogTitle>
            <BootstrapDialogTitle id="dependants-dialog-title" onClose={handleCloseViewPackage}>
            </BootstrapDialogTitle>
            <DialogContent >
              <ViewPackageDetailsInc package={selectedPolicyPackage} />
            </DialogContent>
          </BootstrapDialog>
        }
      </>
    )


  } else {
    return <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} className={classes.claimwrapper}>
        <Grid container spacing={2} className={classes.claimdetailswrapper}>
          <Grid item xs={12} sm={12} md={12} className={classes.ClaimDetailscontent}>
            <p style={{ textAlign: 'center', marginTop: "30px" }}>No details found</p>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  }
}

export default PoliciesCardInc