import * as types from '../../../../lib/actionTypes.es6'

export const getPolicyProcessingDcpRequest = (guid) =>{
  return{
    type: types.GET_POLICY_PROCESSING_DCP_REQUEST,
    payload: guid,
  }
}

export const getPolicyProcessingDcpSuccess = (payload) =>{
  return{
    type: types.GET_POLICY_PROCESSING_DCP_SUCCESS,
    payload: payload,
  }
}

export const getPolicyProcessingDcpFailure = (error) =>{
  return{
    type: types.GET_POLICY_PROCESSING_DCP_FAILURE,
    payload: error,
  }
}


export function policyActionRequest(payload) {
  return {
    type: types.POLICY_ACTION_REQUEST,
    payload,
  };
}

export function policyActionSuccess(response) {
  return {
    type: types.POLICY_ACTION_SUCCESS,
    payload: response,
  };
}

export function policyActionFailure(error) {
  return {
    type: types.POLICY_ACTION_FAILURE,
    payload: error,
  };
}


export function resetPolicyActionSuccess(error) {
  return {
    type: types.RESET_POLICY_ACTION_SUCCESS,
  };
}

export function resetPolicyActionError(error) {
  return {
    type: types.RESET_POLICY_ACTION_ERROR,
  };

}

export function setPolicyToApprove(response) {
  return {
    type: types.SET_POLICY_TO_APPROVE,
    payload: response,
  };
}

export function resetPolicyToApprove(response) {
  return {
    type: types.RESET_POLICY_TO_APPROVE,
  };
}


export function policyRejectActionRequest(payload) {
  return {
    type: types.POLICY_REJECT_ACTION_REQUEST,
    payload,
  };
}

export function policyRejectActionSuccess(response) {
  return {
    type: types.POLICY_REJECT_ACTION_SUCCESS,
    payload: response,
  };
}

export function policyRejectActionFailure(error) {
  return {
    type: types.POLICY_REJECT_ACTION_FAILURE,
    payload: error,
  };
}


export const cancelPolicyActionRequest = (payload) =>{
  return{
    type: types.CANCEL_POLICY_ACTION_REQUEST,
    payload
  }
}

export const cancelPolicyActionSuccess =  (response) =>{
  return{
    type: types.CANCEL_POLICY_ACTION_SUCCESS,
    payload: response
  }
}

export const cancelPolicyActionFailure = (error) =>{
  return{
    type: types.CANCEL_POLICY_ACTION_FAILURE,
    payload: error
  }
}




export function addPolicyNotesRequest(payload) {
  return {
    type: types.ADD_POLICY_NOTES_REQUEST,
    payload,
  };
}

export function addPolicyNotesSuccess(response) {
  return {
    type: types.ADD_POLICY_NOTES_SUCCESS,
    payload: response,
  };
}

export function addPolicyNotesFailure(error) {
  return {
    type: types.ADD_POLICY_NOTES_FAILURE,
    payload: error,
  };
}


export function resetAddPolicyNotesSuccess(error) {
  return {
    type: types.RESET_ADD_POLICY_NOTES_SUCCESS,
  };
}

export function resetAddPolicyNotesError(error) {
  return {
    type: types.RESET_ADD_POLICY_NOTES_ERROR,
  };
}


export function deletepolicyDocumentRequest(payload) {
  return {
    type: types.DELETE_POLICY_DOCUMENT_REQUEST,
    payload,
  };
}

export function deletepolicyDocumentSuccess(response) {
  return {
    type: types.DELETE_POLICY_DOCUMENT_SUCCESS,
    payload: response,
  };
}

export function deletepolicyDocumentFailed(error) {
  return {
    type: types.DELETE_POLICY_DOCUMENT_FAILURE,
    payload: error,
  };
}

export function setpolicyDocumentsToDelete(response) {
  return {
    type: types.SET_POLICY_DOCUMENT_TO_DELETE,
    payload: response,
  };
}

export function resetHardDeletepolicyDocumentSuccess() {
  return {
    type: types.RESET_DELETE_POLICY_DOCUMENT_SUCCESS,
  };
}

export function resetPolicyDocumentToDelete() {
  return {
    type: types.RESET_POLICY_DOCUMENT_TO_DELETE,
  };
}
export function resetHardDeletepolicyDocumentError() {
  return {
    type: types.RESET_POLICY_DELETE_DOCUMENT_ERROR,
  };
}



export const getPaymentsByPolicyGuidRequest = (guid) =>{
  return{
    type: types.GET_PAYMENTS_BY_POLICY_GUID_REQUEST,
    payload: guid,
  }
}

export const getPaymentsByPolicyGuidSuccess = (payload) =>{
  return{
    type: types.GET_PAYMENTS_BY_POLICY_GUID_SUCCESS,
    payload: payload,
  }
}

export const getPaymentsByPolicyGuidFailure = (error) =>{
  return{
    type: types.GET_PAYMENTS_BY_POLICY_GUID_FAILURE,
    payload: error,
  }
}