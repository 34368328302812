import React, { useState,useEffect } from 'react'
import { useStyle } from "./Style";
import ModalInc from '../../../../../shared-ui/ModalInc'
import ButtonInc from '../../../../../shared-ui/ButtonInc'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Grid from "@material-ui/core/Grid";
import DropzoneS3Uploader from "react-dropzone-s3-uploader";
import { getS3BucketSignedUrlForClaimDcpDocuments } from '../../../../../lib/requests.es6';
import ProgressState from './ProgressState';


const AddClaimDocuments = (props) => {

  useEffect(() => {
    setDocDescription(props.description)
   
  }, [props.description])
  

  const classes = useStyle();

  const [docDescription, setDocDescription] = useState("");
  const [s3Url] = useState('https://claim-dcp-documents-uploads.s3.amazonaws.com');
  const [progress, setProgress] = useState(0);
  const [errorMessage, setErrorMessage ] = useState(null)
  const [descError, setDescError] = useState(null)


  const handleFinishedUpload = (info) => {
    const payload = {
      claim_guid: props.claim_guid,
      description: docDescription,
      url: info.fileUrl.replace('undefined', info.bucketKey)
    }
    props.saveClaimDocument(payload);
  }

  const getS3Ur = (file, callback) => getS3BucketSignedUrlForClaimDcpDocuments(props.claim_guid, file, callback)

  const uploadOptions = {
    getSignedUrl: getS3Ur,
    s3Url: s3Url,
    uploadRequestHeaders: {},
    onProgress: (progress) => setProgress(progress),
    onError: (errorMessage) => setErrorMessage(errorMessage),

  };

  const handleChange = (event) => {
    const { name, value } = event.target

    if (name === "docsdescription") {
      setDocDescription(value);
      setDescError(null)
    }

  }
  const reset = () => {
    setDocDescription('');
    setProgress(0)
    setDescError(null)
    props.toggleClaimDocumentsDialog();
    window.location.reload();
  }

  return (
    <>

      <ModalInc
        modalTitle="Upload Claim Documents"
        subTitle="Fill the below fields to add claim documents"
        open={props.openClaimDocumentsDialog}
        onClose={props.toggleClaimDocumentsDialog}
        fullWidth
      >
        <ValidatorForm onSubmit={() => {}} >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <TextValidator
                name='docsdescription'
                id="docsdescription"
                label="Document Description "
                disabled={props.description}
                value={docDescription}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                validators={['required']}
                errorMessages={['Please enter Document Description']}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <div className={classes.uploadsection}>
                {descError && <p style={{color: 'red'}}>{descError}</p>}
                <DropzoneS3Uploader
                  onFinish={handleFinishedUpload}
                  upload={uploadOptions}
                  s3Url={s3Url}
                  passChildrenProps={false}
                  maxSize={1024 * 1024 * 12}
                  className={classes.uploadzone}
                  {...(!docDescription && { onDrop: () => setDescError('please add document description before uploading') })}
                  style={{
                    width: "100%",
                    height: "250px",
                    background: "#FFFFFF",
                    border: "1px dashed #B8B8B8",
                    marginTop: "40px",
                    padding: " 70px 0"
                  }}
                >
                  <div >
                    <ProgressState progress={progress} errorMessage={errorMessage} />

                  </div>
                </DropzoneS3Uploader>
              </div>
              {props.error && <p style={{color: 'red'}}>{props.error}</p>}
            </Grid>

          </Grid>
          <Grid container spacing={2} >
            <Grid item xs={12} sm={12} md={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6} style={{textAlign: 'center'}}>
                  <ButtonInc
                    size="large"
                    onClick={reset}
                    type="submit"
                    variant="contained"
                    style={{ width: "221px", height: "55px", background: "#FBA92D",  marginTop: '3%'}}
                  > I'm  Done</ButtonInc>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <ButtonInc
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={props.toggleClaimDocumentsDialog}
                    style={{ width: "221px", height: "55px", background: "#FBA92D",  marginTop: '3%'}}
                  >
                    Cancel
                  </ButtonInc>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ValidatorForm>
      </ModalInc>

    </>
  )
}

export default AddClaimDocuments