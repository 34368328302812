import * as types from "../../../lib/actionTypes.es6"



export const getAuditsRequest = (payload) =>{
  return{
    type: types.GET_AUDITS_REQUEST,
    payload: payload,
  }
}

export const getAuditsSuccess = (payload) =>{
  return{
    type: types.GET_AUDITS_SUCCESS,
    payload: payload,
  }
}

export const getAuditsFailure = (error) =>{
  return{
    type: types.GET_AUDITS_FAILURE,
    payload: error,
  }
}