import * as React from 'react';
import { hashHistory } from 'react-router';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useStyle } from "./Style";
import { Grid } from '@mui/material';
import ButtonInc from '../../../../../shared-ui/ButtonInc';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';


//components
import UserProfile from '../UserProfile/UserProfile';
import AccessBankPolicies from '../Policies/index'
import AccessBankPayments from '../Payments/index'
import AccessBankQuotes from '../Quotes/index'
import AccessBankPremiums from '../Premiums/index'
import AccessBankClaims from '../Claims/index'
import AccessBankSmsActivity from '../SmsActivity/index'
import { hasAuthourity } from '../../../../../lib/access.es6';
import Audits from '../../../../../containers/Audits';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const NavigationWrapper = (props) => {
  const classes = useStyle();
  const [value, setValue] = React.useState(0);


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const makeAClaim = (customer_guid) => {
    hashHistory.push(`admin/claim-initiation-complex/${customer_guid}`);

  }
  return (
    <>
      <Box
        sx={{ flexGrow: 1, display: 'flex', height: "auto" }}
      >
        <Tabs
          orientation="vertical"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{ borderRight: 1, borderColor: 'divider', width: "18%" }}
          className={classes.tabs}
        >
          <Tab label="Profile" {...a11yProps(0)} />
          <Tab label="Policies" {...a11yProps(1)} />
          <Tab label="Payments" {...a11yProps(2)} />
          <Tab label="Quotes" {...a11yProps(3)} />
          <Tab label="Premiums" {...a11yProps(4)} />
          <Tab label="Claims"  {...a11yProps(5)} />
          <Tab label="SMS Activity"  {...a11yProps(6)} />
          <Tab label={"Audits "}  {...a11yProps(7)} />

        </Tabs>
        <TabPanel value={value} index={0} className={classes.tabPanels}>
          <Grid container spacing={2} className={classes.tabheading}>
            <Grid item xs={12} sm={6} md={6}
            >
              <h1>{props.labels.customerProfile}</h1>

            </Grid>
            <Grid item xs={12} sm={6} md={6}
              style={{
                textAlign: "end"
              }}
            >
              <ButtonInc
                onClick={props.toggleEditCustomerDialog}
                style={{
                  border: "1px solid #FBA92D",
                  borderRadius: "8px",
                  background: "#fff",
                  padding: "5px 35px",
                  display: hasAuthourity('CAPTURE_POLICY') ? 'inline-flex' : 'none'
                }}
              >
                <span style={{ color: "#000000" }}>{props.labels.editUser}</span>
                <EditOutlinedIcon sx={{
                  color: "#000000",
                  marginLeft: "10px"
                }} />
              </ButtonInc>

            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <UserProfile
                customerData={props.customerData}
                loading={props.loading}
                labels={props.labels}
                getAgentDetails={props.getAgentDetails}
                profileData={props.profileData}
              />
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1} className={classes.tabPanels}>

          <Grid container spacing={2} className={classes.tabheading}>
            <Grid item xs={12} sm={6} md={6}
            >
              <h1>Policies</h1>

            </Grid>
            <Grid item xs={12} sm={6} md={6}
              style={{
                textAlign: "end"
              }}
            >
              <ButtonInc
                onClick={() => props.createQuote(props.customer_guid)}
                style={{
                  border: "1px solid #FBA92D",
                  borderRadius: "8px",
                  background: "#fff",
                  padding: "5px 35px",
                  display: hasAuthourity('CAPTURE_POLICY') ? 'inline-flex' : 'none'

                }}
              >
                <span style={{ color: "#000000" }}>{"Create a Quote"}</span>
                <EditOutlinedIcon sx={{
                  color: "#000000",
                  marginLeft: "10px"
                }} />
              </ButtonInc>

            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <AccessBankPolicies />
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel value={value} index={2} className={classes.tabPanels}>

          <Grid container spacing={2} className={classes.tabheading}>
            <Grid item xs={12} sm={6} md={6}
            >
              <h1>Payments</h1>

            </Grid>
            <Grid item xs={12} sm={6} md={6}
              style={{
                textAlign: "end"
              }}
            >
              <ButtonInc
                onClick={props.toggleAddPaymentDialog}
                style={{
                  border: "1px solid #FBA92D",
                  borderRadius: "8px",
                  background: "#fff",
                  padding: "5px 35px",
                  display: hasAuthourity('CAPTURE_POLICY') ? 'inline-flex' : 'none'

                }}
              >
                <span style={{ color: "#000000" }}>{"Add Payment"}</span>
                <AddOutlinedIcon sx={{
                  color: "#000000",
                  marginLeft: "10px"
                }} />
              </ButtonInc>

            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <AccessBankPayments
                toggleAddPaymentDialog={props.toggleAddPaymentDialog}
                openAddPaymentDialog={props.openAddPaymentDialog}

              />
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel value={value} index={3} className={classes.tabPanels}>

          <Grid container spacing={2} className={classes.tabheading}>
            <Grid item xs={12} sm={6} md={6}
            >
              <h1>Quotes</h1>

            </Grid>
            <Grid item xs={12} sm={6} md={6}
              style={{
                textAlign: "end"
              }}
            >
              <ButtonInc
                onClick={() => props.createQuote(props.customer_guid)}
                hasAuthourity={hasAuthourity('CAPTURE_POLICY')}
                style={{
                  border: "1px solid #FBA92D",
                  borderRadius: "8px",
                  background: "#fff",
                  padding: "5px 35px",
                  display: hasAuthourity('CAPTURE_POLICY') ? 'inline-flex' : 'none'
                }}
              >
                <span style={{ color: "#000000" }}>{"Create a Quote"}</span>
                <EditOutlinedIcon sx={{
                  color: "#000000",
                  marginLeft: "10px"
                }} />
              </ButtonInc>

            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <AccessBankQuotes />
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel value={value} index={4} className={classes.tabPanels}>

          <Grid container spacing={2} className={classes.tabheading}>
            <Grid item xs={12} sm={6} md={6}
            >
              <h1>Premiums</h1>

            </Grid>
            <Grid item xs={12} sm={6} md={6}
              style={{
                textAlign: "end"
              }}
            >


            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <AccessBankPremiums />
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel value={value} index={5} className={classes.tabPanels}>

          <Grid container spacing={2} className={classes.tabheading}>
            <Grid item xs={12} sm={6} md={6}
            >
              <h1>Claims</h1>

            </Grid>
            <Grid item xs={12} sm={6} md={6}
              style={{
                textAlign: "end"
              }}
            >
              <ButtonInc
                onClick={() => makeAClaim(props.customer_guid)}
                hasPermision={hasAuthourity('ACCESSBANK_FUNERAL_CLAIM_RECEIVERS')}
                style={{
                  border: "1px solid #FBA92D",
                  borderRadius: "8px",
                  background: "#fff",
                  padding: "5px 35px",
                  display: hasAuthourity('ACCESSBANK_FUNERAL_CLAIM_RECEIVERS') ? 'inline-flex' : 'none'

                }}
              >
                <span style={{ color: "#000000" }}>{"Make a claim"}</span>
                <ChevronRightOutlinedIcon sx={{
                  color: "#000000",
                  marginLeft: "10px"
                }} />
              </ButtonInc>

            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <AccessBankClaims />
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel value={value} index={6} className={classes.tabPanels}>

          <Grid container spacing={2} className={classes.tabheading}>
            <Grid item xs={12} sm={6} md={6}
            >
              <h1>SMS Activities</h1>

            </Grid>
            <Grid item xs={12} sm={6} md={6}
              style={{
                textAlign: "end"
              }}
            >

            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <AccessBankSmsActivity />

            </Grid>
          </Grid>
        </TabPanel>


        <TabPanel value={value} index={7} className={classes.tabPanels}>

          <Grid container spacing={2} className={classes.tabheading}>
            <Grid item xs={12} sm={6} md={6}
            >
              <h1>Audits</h1>

            </Grid>
            <Grid item xs={12} sm={6} md={6}
              style={{
                textAlign: "end"
              }}
            >

            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <Audits entity_guid={props.customer_guid} />
            </Grid>
          </Grid>
        </TabPanel>
      </Box>
    </>
  )
}

export default NavigationWrapper