import React from 'react'
import MenuItem from '@mui/material/MenuItem';
import { SelectValidator, ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { Grid } from '@mui/material';
import { useStyle } from "./Style";
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

const ClaimDetails = (props) => {
  const classes = useStyle();
  const [claimType, setClaimType] = React.useState('');
  const [relationshipToMainMember, setRelationshipToMainMember] = React.useState('')
  const [hospitalAdmissionDate, setHospitalAdmissionDate] = React.useState('');
  const [hospitalDischargeDate, setHospitalDischargeDate] = React.useState('');
  const [dateOfDeath, setDateOfDeath] = React.useState('');
  const [dateOfEvent, setDateOfEvent] = React.useState('');
  const [IgnoreMaxDays, setIgnoreMaxDays] = React.useState(false)
  const [showIgnoreMaxDays, setShowIgnoreMaxDays] = React.useState(false)

  const handleChange = (event) => {

    const { name, value } = event.target
    if (name === "selectClaimtype") {
      setClaimType(value)
      setShowIgnoreMaxDays(true)
      props.setAdditionalState("type", value)
      handleResetField()
    }
    if (name === "selectRelationshipToMainMember") {
      setRelationshipToMainMember(value)
      props.setAdditionalState("relation_to_main_member", value)
    }
    if (name === "hospitaladmisiondate") {
      setHospitalAdmissionDate(value)
      props.setAdditionalState("hospital_admission_date", value)
    }
    if (name === "hospitaldischargedate") {
      setHospitalDischargeDate(value)
      props.setAdditionalState("hospital_discharge_date", value)

    }
    if (name === "dateofdeath") {
      setDateOfDeath(value)
      props.setAdditionalState("death_date", value)
    }
    if (name === "dateofevent") {
      setDateOfEvent(value)
      props.setAdditionalState("event_date", value)
    }

  }

  const handleIgnoreMaxDays = () => {
    setIgnoreMaxDays(true)
    props.setAdditionalState("ignore_max_days", true)
  }

  const handleResetField = () =>{
    setRelationshipToMainMember(''); // Reset relationship
    setHospitalAdmissionDate(''); // Reset hospital admission date
    setHospitalDischargeDate(''); // Reset hospital discharge date
    setDateOfDeath(''); // Reset date of death
    setDateOfEvent(''); // Reset date of event
    setIgnoreMaxDays(false); // Reset ignore max days

    props.setAdditionalState("relation_to_main_member", '');
    props.setAdditionalState("hospital_admission_date", '');
    props.setAdditionalState("hospital_discharge_date", '');
    props.setAdditionalState("death_date", '');
    props.setAdditionalState("event_date", '');
    props.setAdditionalState("ignore_max_days", false);
  }

  const handleSubmit = () => {

  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <ValidatorForm onSubmit={() => handleSubmit()} >

            <Grid container spacing={2} className={classes.claimDetailsWrapper}>
              <Grid item xs={12} sm={12} md={12} >
                <SelectValidator
                  labelId="select-claim-type"
                  id="select-claim-type"
                  value={claimType}
                  name='selectClaimtype'
                  type="text"
                  label="Select  Claim Type"
                  onChange={handleChange}
                  variant="outlined"
                  validators={['required']}
                  errorMessages={['Please select claim type']}
                  style={{ width: "100%" }}
                  sx={{
                    fontFamily: "Open Sans",
                    fontStyle: "normal",
                    fontWeight: 300,
                    fontSize: "12px",
                    lineHeight: "16px",
                    letterSpacing: "0.005em",
                    color: "#000000",

                  }}
                >

                  {
                    props.claimTypes.map((claim, index) => (
                      <MenuItem
                        key={index}
                        sx={{
                          fontFamily: "Open Sans",
                          fontStyle: "normal",
                          fontWeight: 300,
                          fontSize: "12px",
                          lineHeight: "16px",
                          letterSpacing: "0.005em",
                          color: "#000000",
                        }}
                        value={claim}>{claim}</MenuItem>
                    ))
                  }

                </SelectValidator>
              </Grid>
              <Grid item xs={12} sm={12} md={12} >
                <SelectValidator
                  labelId="select-relationship-to-main-member"
                  id="select-relationship-to-main-member"
                  value={relationshipToMainMember}
                  name='selectRelationshipToMainMember'
                  type="text"
                  label=" Relationship to main member"
                  onChange={handleChange}
                  variant="outlined"
                  validators={['required']}
                  errorMessages={[' relationship to main member']}
                  style={{ width: "100%" }}
                  sx={{
                    fontFamily: "Open Sans",
                    fontStyle: "normal",
                    fontWeight: 300,
                    fontSize: "12px",
                    lineHeight: "16px",
                    letterSpacing: "0.005em",
                    color: "#000000",
                  }}
                >

                  {
                    props.relationToMainMember.map((relationship, index) => (
                      <MenuItem
                        key={index}
                        sx={{
                          fontFamily: "Open Sans",
                          fontStyle: "normal",
                          fontWeight: 300,
                          fontSize: "12px",
                          lineHeight: "16px",
                          letterSpacing: "0.005em",
                          color: "#000000",
                        }}
                        value={relationship}>{relationship}</MenuItem>
                    ))
                  }

                </SelectValidator>
              </Grid>
              {
                claimType === "Hospital" ? (
                  <>
                    <Grid item xs={12} sm={12} md={12}>

                      <TextValidator
                        name='hospitaladmisiondate'
                        id="hospitaladmisiondate"
                        label="Hospital Admission Date*"
                        value={hospitalAdmissionDate}
                        onChange={handleChange}
                        fullWidth
                        type='date'
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                        validators={['required']}
                        errorMessages={["please select hospital admission date"]}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>

                      <TextValidator
                        name='hospitaldischargedate'
                        id="hospitaldischargedate"
                        label="Hospital Discharge Date*"
                        value={hospitalDischargeDate}
                        onChange={handleChange}
                        fullWidth
                        type='date'
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                        validators={['required']}
                        errorMessages={["please select hospital discharge date"]}
                      />
                    </Grid>

                  </>
                ) : claimType === "Death" ? (
                  <>
                    <Grid item xs={12} sm={12} md={12}>

                      <TextValidator
                        name='dateofdeath'
                        id="dateofdeath"
                        label="Date of death* "
                        value={dateOfDeath}
                        onChange={handleChange}
                        fullWidth
                        type='date'
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                        validators={['required']}
                        errorMessages={["please select date of death"]}
                      />
                    </Grid>
                  </>
                ) : claimType === "Personal_Accident" ? (
                  <>
                    <Grid item xs={12} sm={12} md={12}>

                      <TextValidator
                        name='dateofevent'
                        id="dateofevent"
                        label="Date of event* "
                        value={dateOfEvent}
                        onChange={handleChange}
                        fullWidth
                        type='date'
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                        validators={['required']}
                        errorMessages={["please select date of event"]}
                      />
                    </Grid>
                  </>
                ) : null
              }
              {
                showIgnoreMaxDays &&
                <Grid item xs={12} sm={12} md={12} className={classes.ignoreMaxDaysCheck}>
                  <FormGroup>
                    <FormControlLabel labelPlacement="start" required control={<Checkbox
                       checked={IgnoreMaxDays}
                      onChange={handleIgnoreMaxDays}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />} label="Ignore max days allowed after discharge " />

                  </FormGroup>
                </Grid>


              }
            </Grid>

            {/* <Grid container spacing={2}>
              <Grid item xs={6} >
                <ButtonInc
                  variant="contained"
                  color="primary"
                  size="large"
                  type='submit'
                  style={{ marginTop: "20px", float: "right" }}
                >
                  Buy Policy
                </ButtonInc>
              </Grid>
              <Grid item xs={6}>
                <ButtonInc
                  variant="contained"
                  color="primary"
                  size="large"
                  style={{ marginTop: "20px" }}
                  onClick={props.toggleBuyPolicyForm}
                >
                  Cancel
                </ButtonInc>
              </Grid>
            </Grid> */}


          </ValidatorForm>
        </Grid>
      </Grid>

    </>
  )
}

export default ClaimDetails