import { take, call, put } from 'redux-saga/effects';
import * as types from '../../../../lib/actionTypes.es6'
import * as actions from './actions';

import { getPaymentProcessingDcp , paymentApproveAction, paymentRejectAction } from '../../../../lib/requests.es6';

import { GAloggerException } from '../../../../lib/monitoring.es6';
import { unAuthorizedNotification } from '../../../../lib/access.es6';

export function* getPaymentProcessingDcpRequestWatcher() {
  for (;;) {
    const request = yield take(types.GET_PAYMENT_PROCESSING_DCP_REQUEST);
    try {
      const response = yield call(getPaymentProcessingDcp, request.payload);
      yield put(actions.getPaymentProcessingDcpSuccess(response.data));
    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.getPaymentProcessingDcpFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}


export function* paymentApproveActionRequestWatcher() {
  for (;;) {
    const request = yield take(types.PAYMENT_APPROVE_ACTION_REQUEST);
    try {
      const response = yield call(paymentApproveAction, request.payload);
      yield put(actions.paymentApproveActionSuccess(response.data))
    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.paymentApproveActionFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}

export function* paymentRejectActionsRequestWatcher() {
  for (;;) {
    const request = yield take(types.PAYMENT_REJECT_ACTION_REQUEST);
    try {
      const response = yield call(paymentRejectAction, request.payload);
      yield put(actions.paymentRejectActionSuccess(response.data))
    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.paymentRejectActionFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}