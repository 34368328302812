import { hashHistory } from 'react-router';
import moment from 'moment';
import { store } from '../store.es6';
import { find } from 'lodash';

import { goToPage } from './utils.es6';
import posthog from 'posthog-js';

import {
  sessionRefreshRequest,
  unauthorizedAction,
  sessionExpireAlert,
} from '../containers/Login/actions.es6';
import defaultLogo from '../images/default_logo.png';
import ugandaLogo from '../images/utl_logo.png';
import orangeLogo from '../images/orange_logo.png';

export function tokenRefresh() {
  const authString = localStorage.getItem('auth');
  const authObject = JSON.parse(authString);
  const currentTimestamp = Date.now();
  // check if token has expired
  if (currentTimestamp > authObject.expires_in) {
    store.dispatch(sessionExpireAlert());
  } else if (authObject.expires_in - currentTimestamp < 300000) {
    store.dispatch(sessionRefreshRequest());
  }
}

export function sesssionMonitor() {
  const authString = localStorage.getItem('auth');

  let britamPathname = "/britam"
  let currentPathname =hashHistory.getCurrentLocation().pathname

  if (currentPathname === britamPathname){
    goToPage(britamPathname)
    return
  }

  if (!authString) {
    goToPage('login');
    return;
  }
  let authData = null;
  try {
    authData = JSON.parse(authString);
  } catch (e) {
    goToPage('login');
    return;
  }

  const currentTimestamp = Date.now();
  const expirationTimestamp = authData.expires_in;

  const safetyMargin = 1000;

  const remaingTime = expirationTimestamp - currentTimestamp - safetyMargin;
  if (remaingTime > 0) {
    window.setTimeout(() => {
      store.dispatch(sessionExpireAlert());
    }, remaingTime);
  } else {
    goToPage('login');
  }
}

export function getAuthorizationToken() {
  const authString = localStorage.getItem('auth');
  if (authString) {
    const authObject = JSON.parse(authString);
    const token = authObject.access_token;
    // check if token is available
    if (!token) {
      hashHistory.push('login');
      return null;
    }
    tokenRefresh();
    return token;
  }
  hashHistory.push('login');
  localStorage.clear();
  return null;
}

export function getLanguage() {
  return localStorage.getItem('language');
}

export function getRefreshToken() {
  try {
    const authString = localStorage.getItem('auth');
    const refreshToken = JSON.parse(authString).refresh_token;
    return refreshToken;
  } catch (e) {
    hashHistory.push('login');
    return null;
  }
}

export function getUserAuth() {
  const authString = localStorage.getItem('auth');
  if (authString) {
    return JSON.parse(authString);
  }
  return null;
}

export function hasAuthourity(authority) {
  let found = false;
  const AuthData = getUserAuth();
  // if user is not logged in, then return authority as false
  if (!AuthData) {
    return false;
  }
  const { authorities } = AuthData.user_principle;
  for (let i = 0; i < authorities.length; i++) {
    if (authorities[i].authority === authority) {
      found = true;
      break;
    }
  }
  return found;
}

export function canViewDashboard() {
  return hasAuthourity('VIEW_BUSINESS_REPORT')
}

export function hasFullCashbackAuthority() {
  return (
    hasAuthourity('MARK_CASHBACK_AS_PAID') && hasAuthourity('MARK_CASHBACK_AS_UNPAID') && hasAuthourity('VIEW_CASHBACK')
  );
}
export function getUserEmail() {
  const authString = localStorage.getItem('auth');

  if (authString) {
    const authObject = JSON.parse(authString);
    return authObject.user_principle.email;
  }
  return null;
}


export function getUserFullname() {
  const authString = localStorage.getItem('auth');

  if (authString) {
    const authObject = JSON.parse(authString);
    return authObject.user_fullname;
  }
  return null;
}

export function getUserBranch () {
  const authString = localStorage.getItem('auth');

  if (authString) {
    const authObject = JSON.parse(authString);
    return authObject.branch;
  }
  return null;
}


export function getUserGuid() {
  try {
    const authString = localStorage.getItem('auth');

    if (authString) {
      const authObject = JSON.parse(authString);
      return authObject.user_guid;
    }
    return null;
  } catch (e) {
    return null;
  }
}

export function getPartner() {
  try {
    const partner = JSON.parse(localStorage.getItem('partner'));
    return partner;
  } catch (e) {
    return null;
  }
}

export function partnerHasDebitOrder() {
  const partner = getPartner();
  if (partner) {
    return partner.can_debit;
  }
  return false;
}

export function partnerHasPartialPayments() {
  const partner = getPartner();
  if (partner) {
    return partner.partial_premium_payment_enabled;
  }
  return false;
}

export function partnerHasMobilePopupPayment() {
  const partner = getPartner();
  if (partner) {
    return partner.mobile_payment_popup_active;
  }
  return false;
}

// NOTE: A every point, the user is browsing data for a given partner only
// TODO: Implement this
// TODO: use this everywhere & Pass this along in all calls
export function getPartnerGuid() {
  try {
    return getPartner().guid;
  } catch (e) {
    return null;
  }
}

export function isComplexPartner() {
  try {
    const partner = getPartner();
    const result =  partner && partner.policy_scheme === 'Complex';
    console.log('isComplexPartner', result);
    return result;
  } catch (e) {
    return false;
  }
}

export function hasAIClaimAssessor() {
  try {
    const partner = getPartner();
    const result =  partner && partner.claim_assessment_mode &&  ['Manual', 'SemiAutomatic', 'Automatic'].includes(partner.claim_assessment_mode);
    return result;
  } catch (e) {
    return false;
  }
}

export function getFeatureFlags() {
  try {
    const featureFlags = JSON.parse(localStorage.getItem('feature-flags'));
    return featureFlags;
  } catch (e) {
    return null;
  }
}

export const isFeatureFlagEnabled = (flagName) => {
  const featureFlags = getFeatureFlags();

  if (featureFlags && featureFlags.length > 0) {
    const flag = find(featureFlags, { name: flagName });
    return flag && flag.is_enabled;
  }
  return false;
}

export function asArray(str) {
  if(Array.isArray(str)) {
    return str;
  }
  try {
    if (str.includes('[') === true) {
      str = str.replace('[', '');
    }
    if (str.includes(']') === true) {
      str = str.replace(']', '');
    }
    const re = /\s*,\s*/;
    return str.split(re);
  } catch (e) {
    return null;
  }
}

export function getPartnerWebDisplayFields() {
  try {
    const webDisplayFields = getPartner().web_display_fields;
    return asArray(webDisplayFields);
  } catch (e) {
    return null;
  }
}

export function partnerWebDisplayFieldsContains(field) {
  const webDisplayFields = getPartnerWebDisplayFields();
  if (!webDisplayFields) {
    return false;
  }
  return webDisplayFields.includes(field);
}

export function getPartnerRegistrationFields() {
  try {
    const registrationFields = getPartner().registration_fields;
    return asArray(registrationFields);
  } catch (e) {
    return null;
  }
}

export function partnerRegistrationFieldsContains(field) {
  const registrationFields = getPartnerRegistrationFields();
  if (!registrationFields) {
    return false;
  }
  return registrationFields.includes(field);
}

export function getPartnerCustomerFields() {
  try {
    const customerFields = getPartner().customer_fields;
    return asArray(customerFields);
  } catch (e) {
    return null;
  }
}

export function partnerCustomerFieldsContains(field) {
  const customerFields = getPartnerCustomerFields();

  if (!customerFields) {
    return false;
  }
  return customerFields.includes(field);
}

function getPartnerPolicyFields() {
  try {
    const customerFields = getPartner().policy_fields;
    return asArray(customerFields);
  } catch (e) {
    return null;
  }
}

export function partnerPolicyFieldsContains(field) {
  const policyFields = getPartnerPolicyFields();
  if (!policyFields) {
    return false;
  }
  return policyFields.includes(field);
}

// TODO: Make this robust > handle incorrect data
export function getInternationalCallingCode() {
  try {
    return getPartner().country.international_calling_code;
  } catch (e) {
    return null;
  }
}

export function hasInternationalCallingCode(msisdn) {
  const intCc = getInternationalCallingCode();
  const myReg = new RegExp(`^${intCc}d*`, 'g');
  return !!myReg.exec(msisdn);
}

// TODO: Make this robust > handle incorrect data
export function getLocalNumberLength() {
  try {
    return Number(getPartner().country.local_number_length);
  } catch (e) {
    return null;
  }
}

// TOOD: improve> Using partner country code in 'getInternationalCallingCode' doesn't work
export function addInternationalCallingCode(prefix) {
  return `${prefix} ( ${getInternationalCallingCode()} )`;
}

// TODO: This should be provided by the API in the payload to make it scalable
export function getPartnerLogo() {
  // const prefix = 'https://s3-eu-west-1.amazonaws.com/inclusivity-app/images/inclusivity_logo.png';
  // Local copy "src/images/orange_logo.png";
  let logo = defaultLogo;
  if (getPartnerGuid() === 'ugandatelecomguid') {
    logo = ugandaLogo;
  } else if (getPartnerGuid() === 'orangeguid') {
    logo = orangeLogo;
  } else if (getPartnerGuid() === 'britamguid') {
    logo = "https://www.britam.com/image/company_logo?img_id=85007&t=1598916810842"
  }
  return logo;
}

export function getPartners() {
  try {
    const authString = localStorage.getItem('auth');
    const partners = JSON.parse(authString).user_partners;
    return partners;
  } catch (e) {
    hashHistory.push('login');
    return null;
    // TODO: Display error message
  }
}

export function unAuthorizedNotification(error) {
  if (error.response) {
    if (error.response.status === 403) {
      store.dispatch(
        unauthorizedAction('You are unauthorised to do this operation')
      );
    }
  }
}

export function partnerCanPayClaim() {
  try {
    return getPartner().can_pay_claim;
  } catch (e) {
    return null;
  }
}

export function partnerHasCoolOff() {
  try {
    return getPartner().has_cool_off;
  } catch (e) {
    return null;
  }
}

export function partnerCanDebit() {
  try {
    return getPartner().can_debit;
  } catch (e) {
    return null;
  }
}

export function partnerCanEditDebit() {
  return partnerCanDebit() && getPartner().can_edit_debit;
}

export function partnerPullKyc() {
  try {
    return getPartner().pull_kyc;
  } catch (e) {
    return null;
  }
}

export function partnerClaimTypes() {
  try {
    return getPartner().claim_types;
  } catch (e) {
    return [];
  }
}

export function partnerSupportFixedBenefit() {
  const claimTypes = partnerClaimTypes();
  if (!claimTypes || claimTypes.length === 0) {
    return false;
  }
  return claimTypes.includes('Death');
}

// TODO: The above try..catch might hide bugs
export function partnerLanguages() {
  const partner = getPartner();
  if (!partner) {
    return [];
  }
  return partner.languages;
}

export function countryCode() {
  const partner = getPartner();
  if(!partner) return null
  return partner.country_code;
}

export function isSouthAfricanPartner() {
  const SOUTH_AFRICAN_COUNTRY_CODE = 'SA';
  return countryCode() === SOUTH_AFRICAN_COUNTRY_CODE;
}

export function customerLanguagesVisible() {
  return partnerLanguages().length > 1;
}

export function languageLabel(languageIn) {
  let result = '';
  const languages = partnerLanguages();
  const filter = languages.filter(l => l.code === languageIn);
  if (filter.length !== 0) {
    // NOTE: Assuming we always have only 1 match
    result = filter[0].name;
  }
  return result;
}

export function partnerDefaultLanguage() {
  const partner = getPartner();
  return partner.default_language;
}

export function dateTimeFormat() {
  // TODO: Use the provide country data format
  return 'YYYY-MM-DD HH:mm:ss';
}

export function dateFormat() {
  // TODO: Use the provide country data format
  return 'YYYY-MM-DD';
}

export function toDate(dateString) {
  return moment(dateString, dateFormat(), true).toDate();
}

export function storeCurrentClaimGuid(claimGuid) {
  localStorage.setItem('claimGuid', claimGuid);
}

export function getCurrentClaimGuid() {
  return localStorage.getItem('claimGuid');
}

export const formatNumberWithCommaIncents = (startingNum) => {
  function convertToTwoDecimalPlaces(numberA) {
    return Math.round((numberA + Number.EPSILON) * 100) / 100
  }

  function replaceCommasWithDecimal(numberb) {
    return parseFloat((numberb+"").replace(',', '.'))
  }

  function convertToCents(numberC) {
    return  Number((Number(numberC) * 100).toFixed(0))
  }


  const toDecimal = replaceCommasWithDecimal(startingNum)

  const twoDecimalPlaces = convertToTwoDecimalPlaces(toDecimal)

  const inCents = convertToCents(twoDecimalPlaces)
  return inCents
}
export function numberInCommaFormat(value) {
  if(value===0)return 0
  if(!value) return ""
  return (value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}


export function isVAlidBotswanaNationalId(id) {
    let result = { isValid: false, gender: null, error: null };

    if (/^\d{9}$/.test(id)) {
        // Check gender
        if (id.charAt(4) === '1') {
            result.isValid = true;
            result.gender = "Male";
        } else if (id.charAt(4) === '2') {
            result.isValid = true;
            result.gender = "Female";
        } else {
            result.error = 'Invalid ID Number - gender could not be determined';
        }
    } else {
        result.error = 'Invalid ID Number - not nine digits';
    }
    
    return result;
}

export function isVAlidSouthAfricanNationalId(num) {

  function CalcSumOfString(ValueToSum) {

    let lenghtOfString = ValueToSum.length;
    let sumOfString = 0;
    for (let i = 0; i < lenghtOfString; i++) {
      sumOfString += parseInt(ValueToSum.substr(i, 1));
    }
    return sumOfString;
  }

  function SAIDCheck(IdNumber) {
    let d1 = 0;
    let d2 = 0;
    let d3 = 0;
    let d4 = 0;
    let d5 = 0;
    let d6 = 0;
    let d7 = 0;
    let d8 = 0;
    let d9 = 0;
    let d10 = 0;
    let d11 = 0;
    let d12 = 0;
    let d13 = 0;
    let evsum = 0;
    let odsum = 0;
    let evnum1 = 0;
    let evnum2 = 0;
    let evnum3 = 0;
    let evnum4 = 0;
    let evnum5 = 0;
    let evnum6 = 0;
    let checkDigit = 0;
    if (IdNumber.length === 13) {
      d1 = parseInt(IdNumber.substr(0, 1), 10);
      d2 = parseInt(IdNumber.substr(1, 1), 10);
      d3 = parseInt(IdNumber.substr(2, 1), 10);
      d4 = parseInt(IdNumber.substr(3, 1), 10);
      d5 = parseInt(IdNumber.substr(4, 1), 10);
      d6 = parseInt(IdNumber.substr(5, 1), 10);
      d7 = parseInt(IdNumber.substr(6, 1), 10);
      d8 = parseInt(IdNumber.substr(7, 1), 10);
      d9 = parseInt(IdNumber.substr(8, 1), 10);
      d10 = parseInt(IdNumber.substr(9, 1), 10);
      d11 = parseInt(IdNumber.substr(10, 1), 10);
      d12 = parseInt(IdNumber.substr(11, 1), 10);
      d13 = parseInt(IdNumber.substr(12, 1), 10);
      evnum1 = (d2 * 2);
      evnum2 = (d4 * 2);
      evnum3 = (d6 * 2);
      evnum4 = (d8 * 2);
      evnum5 = (d10 * 2);
      evnum6 = (d12 * 2);
      evsum = (CalcSumOfString(evnum1.toString()))
        + (CalcSumOfString(evnum2.toString()))
        + (CalcSumOfString(evnum3.toString()))
        + (CalcSumOfString(evnum4.toString()))
        + (CalcSumOfString(evnum5.toString()))
        + (CalcSumOfString(evnum6.toString()));

      odsum = d1 + d3 + d5 + d7 + d9 + d11;
      if (((evsum + odsum) % 10) === 0)
        checkDigit = 0;
      else
        checkDigit = 10 - ((evsum + odsum) % 10);

      if (checkDigit !== d13)
        return false;
      else
        return true;
    }
    else {
      return false;
    }
  }
  let result = {
    dob: null,
    age: null,
    gender: null,
    citizenship: null,
    race: null,
    error: null,
    isValid: null,
  };

  if (!num) {
    result.error = 'Invalid ID Number - value not specified';
    return result;
  }

  let substrings = {
    dob: num.substring(0, 6),
    gender: num.substring(6, 7),
    citizenship: num.substring(10, 11),
    race: num.substring(11, 12)

  }

  // DOB & Age

  let id = num;
  let yy = id.substring(0, 2);
  let cc = '19';
  if (parseInt(yy) <= moment().format('YY')) {
    cc = '20';
  }
  let ccyy = cc + yy;
  let mm = id.substring(2, 4);
  let dd = id.substring(4, 6);
  let dob = ccyy + '-' + mm + '-' + dd;
  result.dob = dob;
  result.age = moment().diff(dob, 'years');

  // Check for valid date
  if (isNaN(result.age)) {
    result.error = 'Invalid ID Number - gender could not be determined';
    return result;
  }
  // Gender
  let genderDigit = parseInt(substrings.gender);
  if (genderDigit >= 0 && genderDigit <= 4)
    result.gender = 'Female';
  else if (genderDigit >= 5 && genderDigit <= 9)
    result.gender = 'Male';
  else {
    result.error = 'Invalid ID Number - gender could not be determined';
    return result;
  }

  // Citizenship
  let citizenshipDigit = parseInt(substrings.citizenship);
  switch (citizenshipDigit) {
    case 0:
      result.citizenship = 'SA Citizen';
      break;
    case 1:
      result.citizenship = 'Non-SA Citizen'; break;
    case 2:
      result.citizenship = 'Refugee'; break;
    default:
      result.error = 'Invalid ID Number - citizenship could not be determined';
      return result;
  }

  // Race
  let raceDigit = parseInt(substrings.race);
  switch (raceDigit) {
    case 8:
      result.race = 'White';
      break;
    case 9:
      result.race = 'Black';
      break;
    default:
      result.race = null;
  }

  // Date of month


  result.isValid = SAIDCheck(num);

  return result;

}


export const  capturePosthogEvent = (eventname ) => {
  posthog.capture(`${eventname}`, {
    email: `${getUserEmail()}`,
    name: `${getUserFullname()}`,
    partner: `${getPartnerGuid()}`,
    timestamp: new Date().toLocaleString(),
  });
}

export function calculateLapsedMonths(endDate, startDate) {
  const d1 = moment(endDate);
  const d2 = moment(startDate);

  const totalMonths = d1.diff(d2, 'months');
  console.log('lapsed months is : ', {startDate, endDate, totalMonths});
  

  return totalMonths;
}