import React from "react";
import { connect } from "react-redux";
import ClaimInitiationMobileWrapper from "./ClaimInitiationMobileWrapper";
import { getCustomer } from "../../../../containers/Customer/actions.es6";
import { getAllPoliciesRequest, getAllProductsRequest } from "../../../../containers/CustomerScreen/actions.es6";
import { claimEnumRequest, claimSubmissionRequest } from "../../../../containers/ClaimSubmission/actions.es6";
import { getUserGuid } from "../../../../lib/access.es6";
import { buildFailureMessage, formatStatus } from "../../../../lib/utils.es6";
import { CircularProgress } from "@mui/material";
import ButtonInc from "../../../ButtonInc";
import { hashHistory } from "react-router";


class ClaimInitiationMobile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      claimInitiationPayload: {
        "type": null,
        "customer_guid": null,
        "editor_guid": null,
        "claimed_amount": 0,
        "medical_expense_total_amount": 0,
        "personal_accident_total_amount": 0,
        "property_total_amount": 0,
        "relation_to_main_member": null,
        "event_date": null,
        "death_date": null,
        "start_date": null,
        "end_date": null,
        "hospital_admission_date": null,
        "hospital_discharge_date": null,
        "ignore_max_days": true,
        "ussd_initiated": false
      },
    };
  }

  componentWillMount() {
    this.props.dispatch(getAllProductsRequest())

    let customer_guid = this.props.params.customer_guid
    if (customer_guid) {
      this.props.dispatch(
        getCustomer(customer_guid)
      );

    }


    const requestData = this.props.claimRequestData;
    if (
      requestData.types.length <= 1 ||
      requestData.relationToMainMember.length <= 1
    ) {
      this.props.dispatch(claimEnumRequest());
    }

  }




  getCustomerClaimTypesMobile() {

    const claimTypes = new Set();
    //console.log("this.props.customerData.currentCustomer", this.props.customerData)
    if (!this.props.customerData.currentCustomer) {
      return []
    }
    const policies = this.props.customerScreenData.policies || [];

    for (let i = 0; i < policies.length; i++) {
      const benefits = policies[i].benefits || [];
      for (let j = 0; j < benefits.length; j++) {
        const benefitClaimType = benefits[j].type
        claimTypes.add(benefitClaimType);
      }
    }

    return ["Please Select"].concat(Array.from(claimTypes));
  }

  setAdditionalState(name, value) {
    this.setState((prevState) => ({
      claimInitiationPayload: {
        ...prevState.claimInitiationPayload,
        [name]: value,
      },
    }));
  }

  handleInitiateClaim() {

    const payload = { ...this.state.claimInitiationPayload };
    payload.type = this.state.claimInitiationPayload.type;
    payload.customer_guid = this.props.currentCustomerData && this.props.currentCustomerData.currentCustomer && this.props.currentCustomerData.currentCustomer.guid ? this.props.currentCustomerData.currentCustomer.guid : '';
    payload.editor_guid = getUserGuid();
    payload.claimed_amount = this.state.claimInitiationPayload.claimed_amount;
    payload.medical_expense_total_amount = this.state.claimInitiationPayload.medical_expense_total_amount;
    payload.personal_accident_total_amount = this.state.claimInitiationPayload.personal_accident_total_amount;
    payload.property_total_amount = this.state.claimInitiationPayload.property_total_amount;
    payload.relation_to_main_member = this.state.claimInitiationPayload.relation_to_main_member;
    payload.event_date = this.state.claimInitiationPayload.event_date;
    payload.death_date = this.state.claimInitiationPayload.death_date;
    payload.start_date = this.state.claimInitiationPayload.start_date;
    payload.end_date = this.state.claimInitiationPayload.end_date;
    payload.hospital_admission_date = this.state.claimInitiationPayload.hospital_admission_date;
    payload.hospital_discharge_date = this.state.claimInitiationPayload.hospital_discharge_date;
    payload.ignore_max_days = this.state.claimInitiationPayload.ignore_max_days;

    if (this.state.claimInitiationPayload) {
      const payload = { ...this.state.claimInitiationPayload };
      payload.ussd_initiated = this.state.claimInitiationPayload.ussd_initiated || false;
    }
    let args = { payload };  // Encapsulate payload in args

    // Add ignoreMaxDaysAllowed conditionally if required
    if (this.state.ignoreMaxDaysAllowedAfterDischargeOrDeath) {
      args.ignoreMaxDaysAllowed = true;
    }

    this.props.dispatch(claimSubmissionRequest(args));


  }

  failureMessage() {
    // const labels = this.state.labels;
    const defaultErrorMessage = "An error occurred, this could be due to invalid data";
    const errorMessage = buildFailureMessage(
      defaultErrorMessage,
      this.props.claimRequestData.errors
    );
    return errorMessage;
  }

  handleViewCustomerClaim(customer_guid){
    hashHistory.push("admin/customer_status/" + customer_guid);
  }
  successMessage() {
    let successMessage = "Claim successfully submitted";
    
    const policyPurchaseInfo = this.props.customerData.currentCustomer.claimInfo;
    
    if (policyPurchaseInfo && policyPurchaseInfo.message) {
      successMessage = policyPurchaseInfo.message;
    }
    
    return (
      <>
        {successMessage}
        <ButtonInc style={{marginTop: "30px"}} onClick={() => hashHistory.push(`admin/customer_status/${this.props.customerData.currentCustomer.guid}`)}>
          View Customer
        </ButtonInc>
      </>
    );
}


  displayUserFeedback() {
    const resultError = {
      display: this.props.claimRequestData.errors ? 'block' : 'none',
    };

    const resultSuccess = {
      display: this.props.claimRequestData.claimInfo ? 'block' : 'none',
    };

    if (this.props.claimRequestData.loader) {
      return (
        <center>
          <CircularProgress />
        </center>
      );
    }
    return (
      <div>
        <center style={resultError}>
          {' '}
          <p style={{
            color: 'red', fontFamily: "Open Sans",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "15px",
          }}>{this.failureMessage()} </p>{' '}
        </center>
        <center style={resultSuccess}>
          {' '}
          <p style={{
            color: 'green', fontFamily: "Open Sans",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "15px",
          }}>{this.successMessage()}</p>{' '}
        </center>
        <center
          style={{ display: this.state.validationErrors ? 'block' : 'none' }}
        >
          {' '}
          <p style={{
            color: 'red', fontFamily: "Open Sans",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "15px",
          }}>{this.state.validationErrors}</p>{' '}
        </center>
      </div>
    );
  }

  displayOutcome() {
    const claimInfo = this.props.claimRequestData.claimInfo;
    const benefits = claimInfo ? claimInfo.benefits : [];
    const nightsApplies = this.state.selectedClaimType === 'Hospital';
    const benefitColumnLabel = nightsApplies ? 'nightlyBenefit' : 'benefit';

    return (

      <div>

        <p
          style={{
            display: benefits.length === 0 ? 'block' : 'none',
            fontFamily: "Open Sans",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "15px",
          }}
        >
          No policies found please buy a  policy
        </p>
        <table
          className="table table-striped table-hover"
          style={{
            display: benefits.length === 0 ? 'none' : 'block',
            fontFamily: "Open Sans",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "15px",
          }}
        >
          <thead>
            <tr>
              <th style={{
                fontFamily: "Open Sans",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "12px",
                lineHeight: "15px",
              }}> Product Name</th>
              <th style={{
                fontFamily: "Open Sans",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "12px",
                lineHeight: "15px",
              }}>Benefits</th>
              <th
                style={{
                  display: nightsApplies ? 'block' : 'none',
                  fontFamily: "Open Sans",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "12px",
                  lineHeight: "15px",
                }}
              >
                Nights
              </th>
              <th>{benefitColumnLabel}</th>
            </tr>
          </thead>
          <tbody>
            {benefits.map(benefit => (
              <tr key={benefit.guid}>
                <td style={{
                  fontFamily: "Open Sans",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "12px",
                  lineHeight: "15px",
                }}>{benefit.product_name}</td>
                <td style={{
                  fontFamily: "Open Sans",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "12px",
                  lineHeight: "15px",
                }}>{formatStatus(benefit.type)}</td>
                <td
                  style={{
                    display: nightsApplies ? 'block' : 'none',
                    fontFamily: "Open Sans",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "12px",
                    lineHeight: "15px",
                  }}
                >
                  {benefit.night ? benefit.night : 'N/A'}
                </td>
                <td style={{
                  fontFamily: "Open Sans",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "12px",
                  lineHeight: "15px",
                }}>{benefit.amount_benefit}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )



  }


  displayNotes() {
    const claimInfo = this.props.claimRequestData.claimInfo;
    const feedback = claimInfo ? claimInfo.feedback : '';
    const feedbackArray = feedback.split('&');
    return (
      <div className="x_panel">
        <div className="x_content">
          <p style={{
            fontFamily: "Open Sans",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "15px",
          }}>
            {feedbackArray.map((value, index) => (
              <p key={index} style={{   fontFamily: "Open Sans",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "12px",
                lineHeight: "15px",}}>{value} </p>
            ))}
          </p>
        </div>
      </div>
    )
  }



  render() {

    const claimTypes = this.getCustomerClaimTypesMobile()

    if (!this.props.customerData.currentCustomer) {
      return "Loading...";
    }




    return (
      <>
        <ClaimInitiationMobileWrapper
          claimTypes={claimTypes}
          relationToMainMember={this.props.claimRequestData.relationToMainMember}
          handleInitiateClaim={this.handleInitiateClaim.bind(this)}
          setAdditionalState={this.setAdditionalState.bind(this)}
          claimInitiationPayload={this.state.claimInitiationPayload}

          //failureMessage={this.failureMessage.bind(this)}
          displayUserFeedback={this.displayUserFeedback.bind(this)}
          displayOutcome={this.displayOutcome.bind(this)}
          displayNotes={this.displayNotes.bind(this)}
          getPolicies={() =>this.props.dispatch(getAllPoliciesRequest({ guid: this.props.params.customer_guid }))}
          products={this.props.customerScreenData.products}
        />
      </>
    )
  }

}

export default connect((state) => ({
  customerScreenData: state.customerScreen,
  currentCustomerData: state.currentCustomer,
  claimRequestData: state.claimRequest,
  customerData: state.currentCustomer,
  globalData: state.global,
}))(ClaimInitiationMobile);