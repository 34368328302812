import React from "react";
import { useStyle } from "./Style";
import Grid from "@material-ui/core/Grid";
import VisibilityIcon from "@mui/icons-material/Visibility";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

import Tooltip from "@mui/material/Tooltip";
import TableInc from "../../../../../shared-ui/TableInc";
import { formatDateLocale } from "../../../../../lib/utils.es6";
import SkeletonWrapper from "../../../../../containers/NewProductList/components/SkeletonWrapper/SkeletonWrapper";
import { LinearProgressWithLabel } from "../../../../../components/LinearProgressWithLabel";

const Batches = (props) => {
  const classes = useStyle();

  const batches = props.batches || [];

  if (props.loading) return <SkeletonWrapper type="table" loading={true} />;

  const progressPercentage = (batch) => {
    const done = batch.succeeded + batch.failed;
    const percentageRemaining = (done / batch.total_size) * 100;
    if (isNaN(percentageRemaining)) return 0;
    return percentageRemaining > 100 ? 100 : percentageRemaining;
  };

  const hasFailedEnteries = (batch) => {
    if (batch.failed > 0) return true
    return false
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <Grid container spacing={2} className={classes.claimdocswrapper}>
            <Grid item xs={12} sm={12} md={12}>
              <TableInc
                columns={[
                  {
                    title: "Batch Number",
                    field: "batch_number",
                    render: (batch) => batch.batch_number || "N/A",
                  },
                  {
                    title: "progress",
                    field: "total_size",
                    render: (batch) => (
                      <LinearProgressWithLabel
                        value={progressPercentage(batch)}
                      />
                    ),
                  },
                  {
                    title: "Total Size",
                    field: "total_size",
                    render: (batch) => batch.total_size,
                  },
                  {
                    title: "Succeeded",
                    field: "succeeded",
                    render: (batch) => batch.succeeded,
                  },
                  {
                    title: "Failed",
                    field: "failed",
                    render: (batch) => batch.failed,
                  },
                  {
                    title: "Created By",
                    field: "web_agent_name",
                    render: (batch) => batch.web_agent_name || "N/A",
                  },
                  {
                    title: "Created At",
                    field: "created_at",
                    render: (batch) =>
                      formatDateLocale(batch.created_at) || "N/A",
                  },
                  {
                    title: "Actions",
                    field: "created_At",
                    render: (batch) => {
                      return <>
                        <div className={classes.actionbtn}>
                          {hasFailedEnteries(batch) && <Tooltip title="View failed enteries">
                            <span style={{ cursor: "pointer" }}>
                              <a
                                href={batch.url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <VisibilityIcon
                                  onClick={() =>
                                    props.viewFailedEnteries(batch.guid)
                                  }
                                  sx={{ color: " #4B7E34" }}
                                />
                              </a>
                            </span>
                          </Tooltip>}

                          <Tooltip title="download uploaded file">
                            <span style={{ cursor: "pointer" }}>
                              <a
                                href={batch.bucket_key}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <FileDownloadIcon sx={{ color: " #4B7E34" }} />
                              </a>
                            </span>
                          </Tooltip>
                        </div>

                      </>


                    },
                  },
                ]}
                data={(query) =>
                  new Promise((resolve, reject) => {
                    resolve({
                      data: [...batches.data],
                      page: batches.page - 1,
                      totalCount: batches.total,
                    });
                  })
                }
                options={{
                  showTitle: false,
                  pageSizeOptions: [1, 5, 10, 20, 50, 100],
                  pageSize: batches.page_size,
                }}
                onChangeRowsPerPage={(pageSize) => {
                  console.log(pageSize, "pageSize");
                  props.onPageChange(batches.page, pageSize);
                }}
                onChangePage={(page) => {
                  console.log(page, "page");
                  props.onPageChange(page + 1, batches.page_size);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Batches;
