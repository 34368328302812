import * as types from "../../../lib/actionTypes.es6"

export const initialState = {

  error: null,
  loading: false,
  audits: null,
};

export default function auditsReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_AUDITS_REQUEST:
      return { ...state, loading: true, error: null, audits: null };
    case types.GET_AUDITS_SUCCESS:
      return { ...state, loading: false, error: null, audits: action.payload };
    case types.GET_AUDITS_FAILURE:
      return { ...state, loading: false, error: action.payload, audits: null};

    default:
      return state;
  }
}

