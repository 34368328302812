import React from 'react'
import { useStyle } from "./Style";
import Grid from "@material-ui/core/Grid";
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';

const DeviceDamageClaimDetails = (props) => {
  const classes = useStyle();

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "dateOfDamage") {
      handleSubmit(value)
    }

  }


  const handleSubmit = (value) => {

    const tempClaimInitiation = props.claimInitiationPayload;
    tempClaimInitiation["additional_details"] = {
      "device_number": props.eventData.additional_details.device_number
    };
    props.setClaimInitiationPayload(tempClaimInitiation);
  }



  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <div className={classes.headingclaim}>
            <h4> Owner Details</h4>
          </div>
        </Grid>
      </Grid>
      <ValidatorForm onSubmit={() => { }}>
        <Grid container spacing={2} className={classes.selectpolicyWrapper}>
          <Grid item xs={12} md={4} sm={4}>
            <TextValidator
              id="name"
              style={{ width: "100%" }}
              label="first_name"
              name='first_name'
              value={props.member.first_name}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12} md={4} sm={4}>
            <TextValidator
              id="middle_name"
              style={{ width: "100%" }}
              label="middle_name"
              name='middle_name'
              value={props.member.middle_name}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12} md={4} sm={4}>
            <TextValidator
              id="last_name"
              style={{ width: "100%" }}
              label="last_name"
              name='last_name'
              value={props.member.last_name}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12} md={4} sm={4}>
            <TextValidator
              id="relationship"
              style={{ width: "100%" }}
              label="relationship"
              name='relationship'
              value={props.member.relationship}
              onChange={handleChange}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12} md={4} sm={4}>
            <TextValidator
              id="gender"
              style={{ width: "100%" }}
              label="gender"
              name='gender'
              value={props.member.gender}
              variant="outlined"
            />
          </Grid>



          <Grid item xs={12} md={4} sm={4}>
            <TextValidator
              id="date_of_birth"
              style={{ width: "100%" }}
              label="date_of_birth"
              name='date_of_birth'
              value={props.member.date_of_birth}
              variant="outlined"
            />
          </Grid>


        </Grid>

        <hr />

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <div className={classes.headingclaim}>
              <h4>Device  details</h4>
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={2} className={classes.selectpolicyWrapper}>

          <Grid item xs={12} sm={4} md={4}>
            <TextValidator
              id="device_number"
              style={{ width: "100%" }}
              label="device_number"
              name='device_number'
              disabled
              fullWidth
              value={props.eventData.additional_details.device_number}
              variant="outlined"
            />
          </Grid>


          <Grid item xs={12} sm={4} md={4}>
            <TextValidator
              id="dateOfDamage"
              label="Date Of Damage"
              name='dateOfDamage'
              value={props.claimInitiationPayload.event_date}
              disabled
              style={{ width: "100%" }}
              fullWidth
              type='date'
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
            />
          </Grid>


        </Grid>

      </ValidatorForm>
    </>
  )
}

export default DeviceDamageClaimDetails

