import * as types from '../../lib/actionTypes.es6';
import GAlogger, { monitoringEventCategories } from '../../lib/monitoring.es6';

export function claimSubmissionRequest(claimData) {
 // console.log("claimData", claimData)
  return {
    type: types.CLAIM_SUBMISSION_REQUEST,
    payload: claimData,
  };
}

export function claimSubmissionSuccess(claimInfo) {
  const event = monitoringEventCategories.userActions;
  GAlogger(event.name, event.actions.claimSubmission);
  return {
    type: types.CLAIM_SUBMISSION_SUCCESS,
    payload: claimInfo,
  };
}

export function claimSubmissionFail(response) {
  return {
    type: types.CLAIM_SUBMISSION_FAILURE,
    payload: response,
  };
}

export function claimSubmissionReset() {
  return {
    type: types.CLAIM_SUBMISSION_RESET,
  };
}

export function claimEnumRequest() {
  return {
    type: 'CLAIM_TYPES_REQUEST',
  };
}

export function claimTypeEnumSuccess(response) {
  return {
    type: 'CLAIM_TYPES_SUCCESS',
    payload: response,
  };
}

export function claimRelationToMainMemberSuccess(response) {
  return {
    type: 'CLAIM_RELATION_TO_MAIN_MEMBER_SUCCESS',
    payload: response,
  };
}
