import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useStyle } from "./Style";
import { Grid } from '@mui/material';
import { hashHistory } from 'react-router';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';




//components
import UserProfile from '../UserProfile/UserProfile';
import NgsGroupPolicies from '../Policies/index'
import NGSGroupQuotes from '../Quotes/index'
import NGSGroupClaims from '../Claims/index'
import ButtonInc from '../../../../../shared-ui/ButtonInc';
import Audits from '../../../../../containers/Audits';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const NavigationWrapper = (props) => {
  const classes = useStyle();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const makeAClaim = (customer_guid) => {
    hashHistory.push(`admin/claim-initiation-complex/${customer_guid}`);

  }


  return (
    <>
      <Box
        sx={{ flexGrow: 1, display: 'flex', height: "auto" }}
      >
        <Tabs
          orientation="vertical"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{ borderRight: 1, borderColor: 'divider', width: "18%" }}
          className={classes.tabs}
        >
          <Tab label="Profile" {...a11yProps(0)} />
          <Tab label="Policies" {...a11yProps(1)} />
          <Tab label="Quotes" {...a11yProps(2)} />
          <Tab label="Claims"  {...a11yProps(3)} />
          <Tab label={"Audits "}  {...a11yProps(4)} />

        </Tabs>
        <TabPanel value={value} index={0} className={classes.tabPanels}>
          <Grid container spacing={2} className={classes.tabheading}>
            <Grid item xs={12} sm={6} md={6}
            >
              <h1>{props.labels.customerProfile}</h1>

            </Grid>
            <Grid item xs={12} sm={6} md={6}
              style={{
                textAlign: "end"
              }}
            >

            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <UserProfile
                labels={props.labels}
                customerData={props.customerData}
              />
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1} className={classes.tabPanels}>

          <Grid container spacing={2} className={classes.tabheading}>
            <Grid item xs={12} sm={6} md={6}
            >
              <h1>Policies</h1>

            </Grid>
            <Grid item xs={12} sm={6} md={6}
              style={{
                textAlign: "end"
              }}
            >

            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <NgsGroupPolicies />
            </Grid>
          </Grid>
        </TabPanel>


        <TabPanel value={value} index={2} className={classes.tabPanels}>

          <Grid container spacing={2} className={classes.tabheading}>
            <Grid item xs={12} sm={6} md={6}
            >
              <h1>Quotes</h1>

            </Grid>
            <Grid item xs={12} sm={6} md={6}
              style={{
                textAlign: "end"
              }}
            >

            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <NGSGroupQuotes />
            </Grid>
          </Grid>
        </TabPanel>


        <TabPanel value={value} index={3} className={classes.tabPanels}>

          <Grid container spacing={2} className={classes.tabheading}>
            <Grid item xs={12} sm={6} md={6}
            >
              <h1>Claims</h1>

            </Grid>
            <Grid item xs={12} sm={6} md={6}
              style={{
                textAlign: "end"
              }}
            >
              <ButtonInc
                onClick={() => makeAClaim(props.customer_guid)}
                style={{
                  border: "1px solid #FBA92D",
                  borderRadius: "8px",
                  background: "#fff",
                  padding: "5px 35px",

                }}
              >
                <span style={{ color: "#000000" }}>{"Make a claim"}</span>
                <ChevronRightOutlinedIcon sx={{
                  color: "#000000",
                  marginLeft: "10px"
                }} />
              </ButtonInc>

            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <NGSGroupClaims />
            </Grid>
          </Grid>
        </TabPanel>



        <TabPanel value={value} index={4} className={classes.tabPanels}>

          <Grid container spacing={2} className={classes.tabheading}>
            <Grid item xs={12} sm={6} md={6}
            >
              <h1>Audits</h1>

            </Grid>
            <Grid item xs={12} sm={6} md={6}
              style={{
                textAlign: "end"
              }}
            >

            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <Audits entity_guid={props.customerData ? props.customerData.guid : null} />
            </Grid>
          </Grid>
        </TabPanel>
      </Box>
    </>
  )
}

export default NavigationWrapper