import { makeStyles } from "@material-ui/styles";


export const useStyle = makeStyles(() => ({

  claimHeading: {
    "& h6":{
      fontFamily: 'Lato',
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "12px",
      lineHeight: "20px",
      letterSpacing: "0.001em",
      color: "#000000",
      margin: "0 !important",
      wordBreak: "break-all",
    }
  },
  claimDetailsWrapper: {
    "& p": {
        fontFamily: "Open Sans",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "15px",
        color: "#000000",
        textAlign: "center",
        marginBottom: "30px",
    },
    "& input": {
        background: "#F7F7F7",
        borderRadius: "8px",
        fontFamily: "Open Sans",
        fontStyle: "normal",
        fontWeight: 300,
        fontSize: "12px",
        lineHeight: "16px",
        letterSpacing: "0.005em",
        color: "#000000",
    },
    "& label": {
        fontFamily: "Open Sans",
        fontStyle: "normal",
        fontWeight: 300,
        fontSize: "12px",
        letterSpacing: "0.005em",
        color: "#000000",

    }
},
ignoreMaxDaysCheck:{
  "& span": {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: 300,
    fontSize: "12px",
    color: "#000000",
  }, 
},
summaryWrapper:{
  "& p":{
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "15px",
    color: "#000000",
    marginBottom: "30px",
  }
}

}));