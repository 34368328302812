import React from 'react'
import { useStyle } from "./Style";
import Grid from "@material-ui/core/Grid";
import TableInc from '../../../../../shared-ui/TableInc';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Tooltip from '@mui/material/Tooltip';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from "@mui/material/Typography";
import find from 'lodash/find';
import { Chip, Dialog, DialogContent } from '@mui/material';
import { hasAuthourity } from '../../../../../lib/access.es6';
import { Document, Page, pdfjs } from "react-pdf";
import { PRIMARY_THEME_COLOR_SHADE_1 } from '../../../../../lib/constants';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


const ClaimDocuments = (props) => {
  const classes = useStyle();

  const [viewDocumentUrl, setViewDocumentUrl] = React.useState(null);
  const [numPages, setNumPages] = React.useState(null);

  const claimDocs = props.claimDocuments || [];


  let staticDocs = []


  const missingStaticDocs = []

  for (let doc of staticDocs) {
    if (!find(claimDocs, { description: doc.description })) {
      missingStaticDocs.push({ ...doc, status: 'missing' })
      if (!props.missingDocs) {
        props.toggleMissingDocs();
      }

    }
  }

  const result = [
    ...missingStaticDocs,
    ...claimDocs,
    { description: '', status: 'any other doc' }
  ];


  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12}  >
        <Grid container spacing={2} className={classes.claimdocswrapper}>
          <Grid item xs={12} sm={12} md={12}>

            <TableInc
              columns={[
                {
                  title: 'Document  Name', field: 'description'
                },
                {
                  title: 'Status', field: 'created_At', render: rowData => rowData.status === 'missing'
                    ? <Chip
                      size="small"
                      variant="outlined"
                      label={'missing'}
                      style={{
                        margin: '1px',
                        backgroundColor: "red",
                        color: "#fff"
                      }}
                    />
                    : (rowData.status === 'any other doc' ? 'upload  docomunt'
                      : <Chip
                        size="small"
                        variant="outlined"
                        label={'uploaded'}
                        style={{
                          margin: '1px',
                          backgroundColor: "green",
                          color: "#fff"
                        }}
                      />)
                },

                {
                  title: 'Actions', field: 'created_At', render: rowData => {
                    return !(rowData.status === 'missing' || rowData.status === 'any other doc') ? <div className={classes.actionbtn}>
                      <Tooltip title="View Document">
                        <span onClick={() => setViewDocumentUrl(rowData.url)} style={{ cursor: "pointer", display: hasAuthourity('UPDATE_CLAIM') ? 'inline' : 'none', }} ><VisibilityIcon sx={{ color: " #4B7E34" }} /></span>
                      </Tooltip>

                      <Tooltip title="Download Document">
                        <span style={{ cursor: "pointer", display: hasAuthourity('UPDATE_CLAIM') ? 'inline' : 'none', }} ><a href={rowData.url} target='_blank' rel="noopener noreferrer" ><FileDownloadIcon sx={{ color: " #000" }} /></a> </span>
                      </Tooltip>

                      <Tooltip title="Delete Document">
                        <span style={{ cursor: "pointer", marginLeft: "5px" }} onClick={() => props.setClaimDocumentToDelete(rowData)} > <DeleteIcon sx={{ color: "#8F191C" }} /></span>
                      </Tooltip>

                    </div> :

                      <div className={classes.actionbtn}>

                        <Tooltip title="Upload Document">
                          <span style={{ cursor: "pointer", marginLeft: "5px" }} onClick={() => {
                            props.setDescription(rowData.description);
                            props.openUploadDialog();
                          }} > <FileUploadIcon sx={{ color: "#000" }} /></span>
                        </Tooltip>
                      </div>
                  }
                },


              ]}
              data={
                result
              }
              options={{
                pageSize: 7,
                showTitle: false,

              }
              }
            />
          </Grid>

          <Dialog
            fullScreen
            open={!!viewDocumentUrl}
            onClose={() => setViewDocumentUrl(null)}
          >
            <AppBar sx={{ position: 'relative' }}>
              <Toolbar style={{ background: PRIMARY_THEME_COLOR_SHADE_1 }}>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => setViewDocumentUrl(null)}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                  View Claim Documents
                </Typography>
              </Toolbar>
            </AppBar>
            <DialogContent style={{ height: '100%', textAlign: 'center', margin: 'auto' }}>
              {
                (viewDocumentUrl && viewDocumentUrl.includes('.pdf'))
                  ? <Document
                    file={viewDocumentUrl}
                    onLoadSuccess={({ numPages }) => setNumPages(numPages)}
                    style={{ width: '100%', height: '100%', overflow: 'auto', margin: 'auto' }}

                  >
                    {Array.from(new Array(numPages), (el, index) => (
                      <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                    ))}
                  </Document>
                  : viewDocumentUrl ? <div style={{ height: '750px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img src={viewDocumentUrl} alt="Document" style={{ maxHeight: '100%', maxWidth: '100%' }} />
                  </div> : null
              }

            </DialogContent>
          </Dialog>

        </Grid>
      </Grid>
    </Grid>
  )
}

export default ClaimDocuments