import { Grid } from '@mui/material'
import React from 'react'
import ButtonInc from '../../../ButtonInc'
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const ClaimResults = (props) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>

  
      <Grid container spacing={2}>
        <Grid item xs={6} >
          <p style={{
            fontFamily: "Open Sans",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "15px",
            color: "#000000",
          }}>Please click the button to submit the claim</p>
        </Grid>
        <Grid item xs={6}>
          <ButtonInc onClick={() => props.handleInitiateClaim()}>Submit Claim </ButtonInc>
        </Grid>
      </Grid>
      <hr />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          {props.displayUserFeedback()}
        </Grid>
      </Grid>
      <hr />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example"
                TabIndicatorProps={{
                  style: {
                    backgroundColor: '#fba92c', // Set your custom color here
                  },
                }}
              >
                <Tab sx={{
                  fontFamily: "Open Sans",
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: "12px",
                  lineHeight: "15px",
                  color: "#000000 !important",
                }} label="Outcome" {...a11yProps(0)} />
                <Tab sx={{
                  fontFamily: "Open Sans",
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: "12px",
                  lineHeight: "15px",
                  color: "#000000 !important",
                }} label="Notes" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              {
                props.displayOutcome()
              }
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              {
                props.displayNotes()
              }
            </CustomTabPanel>
          </Box>
        </Grid>
      </Grid>




    </>
  )
}

export default ClaimResults