import React, { useState } from 'react'
import ModalInc from '../../../ModalInc'
import MenuItem from '@mui/material/MenuItem';
import {  SelectValidator, ValidatorForm } from 'react-material-ui-form-validator';
//import Grid from "@material-ui/core/Grid";
import ButtonInc from '../../../ButtonInc';
import { getPartner } from '../../../../lib/access.es6';
import AlertDialog from '../../../../components/AlertDialog/index.es6';
import { CircularProgress, Grid } from '@mui/material';

const BuyPolicyMobile = (props) => {
  //console.log("props buy policy", props)
  const [productCode, setProductCode] = useState('');
  const [premiums, setPremiums] = useState([])
  const [productPremium, setProductPremium] = useState('')

  React.useEffect(() => {
    props.getlistPaidProductsRequest()
  }, [])

  

  const handleChange = (event) => {
    const { name, value } = event.target

    if (name === "selectProducttype") {
      setProductCode(value)
      const selectedProduct = props.products.find(product => product.code === value);
      if (selectedProduct) {
        setPremiums(selectedProduct.premiums)
      }
    }
    if (name === "selectProducttypepremium") {
      console.log("premium value", value)
      setProductPremium(value )
    }
  }

  const handleSubmit = () => {

    const payload = {
      "product_code": productCode,
      "msisdn": props.customerData.currentCustomer.msisdn,
      "amount_in_cents": productPremium,
    };
    props.buyMobilePolicy(payload)

    console.log("payload:", payload)
  }

  const resetAfterSucesfullPurchase = () => {
    props.resetPolicyPurchaseMobile()
    props.setShowBuyPolicyForm(false)
    props.setSelectPage('Policies')
   
  }

  const resetAfterFailedPurchase = () => {
    props.resetPolicyPurchaseMobile()
  }

  const currencyCode = getPartner().country.currency_code


  return (
    <>
      <ModalInc
        modalTitle="Buy A Policy"
        subTitle="Fill the below fields to buy a policy"
        open={props.showBuyPolicyForm}
        onClose={props.toggleBuyPolicyForm}
        fullWidth
      >
        <ValidatorForm onSubmit={() => handleSubmit()} >

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <SelectValidator
                labelId="select-product-type"
                id="select-product-type"
                value={productCode}
                name='selectProducttype'
                type="text"
                label="Select  A Product"
                onChange={handleChange}
                variant="outlined"
                validators={['required']}
                errorMessages={['Please select product']}
                style={{ width: "100%" }}
                sx={{
                  fontFamily: "Open Sans",
                  fontStyle: "normal",
                  fontWeight: 300,
                  fontSize: "12px",
                  lineHeight: "16px",
                  letterSpacing: "0.005em",
                  color: "#000000",
                }}
              >

                {
                  props.products.map((product, index) => (
                    <MenuItem
                      key={index}
                      sx={{
                        fontFamily: "Open Sans",
                        fontStyle: "normal",
                        fontWeight: 300,
                        fontSize: "12px",
                        lineHeight: "16px",
                        letterSpacing: "0.005em",
                        color: "#000000",
                      }}
                      value={product.code}>{`${product.name} (${product.cover_type})`}</MenuItem>
                  ))
                }

              </SelectValidator>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <SelectValidator
                labelId="select-product-type-premium"
                id="select-product-type-premium"
                value={productPremium}
                name='selectProducttypepremium'
                type="text"
                label="Select A Premium"
                onChange={handleChange}
                variant="outlined"
                validators={['required']}
                errorMessages={['Please select premium']}
                style={{ width: "100%" }}
                sx={{
                  fontFamily: "Open Sans",
                  fontStyle: "normal",
                  fontWeight: 300,
                  fontSize: "12px",
                  lineHeight: "16px",
                  letterSpacing: "0.005em",
                  color: "#000000",
                }}
              >

                {
                  premiums.map((premium, index) => (
                    <MenuItem
                      key={index}
                      sx={{
                        fontFamily: "Open Sans",
                        fontStyle: "normal",
                        fontWeight: 300,
                        fontSize: "12px",
                        lineHeight: "16px",
                        letterSpacing: "0.005em",
                        color: "#000000",
                      }}
                      value={premium.amount_in_cents}>{`${premium.cardinality} ${premium.granularity
                        }( -- ${ currencyCode + " " + (premium.amount_in_cents / 100).toLocaleString()})`}</MenuItem>
                  ))
                }

              </SelectValidator>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={6} >
              <ButtonInc
                variant="contained"
                color="primary"
                size="large"
                type='submit'
                style={{ marginTop: "20px", float: "right" }}
              >
                Buy Policy
              </ButtonInc>
            </Grid>
            <Grid item xs={6}>
              <ButtonInc
                variant="contained"
                color="primary"
                size="large"
                style={{ marginTop: "20px" }}
                onClick={props.toggleBuyPolicyForm}
              >
                Cancel
              </ButtonInc>
            </Grid>
          </Grid>


        </ValidatorForm>

      </ModalInc>
{
  console.log("props.policyPurchaseData", props.policyPurchaseData)
}

      <AlertDialog
          custom
          show={props.policyPurchaseData.loader}
          size="sm"
          style={{ marginTop: '0', top: '30vh' }}
          confirmBtnText={'ok'}
          showCancel={false}
          showConfirm={false}
          cancelBtnText={'cancel'}
          showTitle={false}
          confirmBtnCssClass
        >
          <CircularProgress />
          <h2>purchasing policy</h2>
        </AlertDialog>

        <AlertDialog
          success
          show={!!props.policyPurchaseData.policy_purchase_succesful}
          size="sm"
          title={props.policyPurchaseData.policyPurchaseInfo ? props.policyPurchaseData.policyPurchaseInfo.message : 'Policy purchase request submitted'}
          style={{ marginTop: '0', top: '30vh' }}
          onConfirm={() => { resetAfterSucesfullPurchase() }}
          confirmBtnText={'ok'}
          showCancel={false}
          cancelBtnText={'cancel'}
          confirmBtnCssClass
        >
        </AlertDialog>


        <AlertDialog
          show={!!props.policyPurchaseData.policy_purchase_failed}
          danger
          title={'Error purchasing policy'}
          onConfirm={() => { resetAfterFailedPurchase() }}
          confirmBtnText={'Try again'}
          confirmBtnCssClass
          showCancel={false}
          style={{ marginTop: '0', top: '30vh' }}
        >
          {props.policyPurchaseData.errors ? props.policyPurchaseData.errors.message : ''}
        </AlertDialog>
    </>
  )
}

export default BuyPolicyMobile