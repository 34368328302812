import { makeStyles } from "@material-ui/styles";


export const useStyle = makeStyles(() => ({

  navigationWrapper: {
    background: "#FFFFFF",
    borderRadius: "8px",
    paddingLeft: "10px",
    height: "85px"
  },
  navigation: {
    "& input": {
      background: "#F7F7F7",
      borderRadius: "8px",
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: 300,
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.005em",
      color: "#000000",
    },
    "& label": {
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: 300,
      fontSize: "14px",
      color: "#000000",
 
      // transformOrigin: "bottom",
    }
  },
  actionFab: {
    "& button": {
      background: "rgb(251, 169, 45) !important",
    },
    "& .MuiSpeedDialAction-staticTooltipLabel": {
      background: "rgb(3, 42, 55)",
      color: "#fff",
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: 300,
      fontSize: "14px",
       width: "180px",
      padding: "10px;"
    }
    // "& span":{
    //   "& span":{
    //     background: "red",
    //      color: "#fff"
    //   }
    // }

  }


}));