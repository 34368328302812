import React, { useState, useEffect, useRef } from 'react'
import { v4 as uuidv4 } from 'uuid';
import { TextValidator, SelectValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Grid from "@material-ui/core/Grid";
import { useStyle } from "./Style";
import MenuItem from '@mui/material/MenuItem';
import Button from "@mui/material/Button";
import { CircularProgress, InputAdornment } from '@mui/material';
import find from 'lodash/find';
import { getLocalNumberLength, getPartner, getPartnerGuid, getUserEmail, getUserFullname, isVAlidBotswanaNationalId, isVAlidSouthAfricanNationalId } from '../../../../../../lib/access.es6';
import AlertDialog from '../../../../../../components/AlertDialog/index.es6';
import moment from 'moment';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import { calculateTotalPremiumInCents, getAgeRangeObject, getNuclearBenefit, getNuclearPremium, isExtendedMember, replicatePrincipalBenefits } from '../../../helpers';
import { getPolicyDependents } from '../../../../../../lib/requests.es6';
import { formatDate } from '../../../../../../lib/utils.es6';
import posthog from 'posthog-js';

const Adddependant = (props) => {
  const classes = useStyle();
  const [firstname, setFirstname] = useState('');
  const [middlename, setMiddleName] = useState('');
  const [lastname, setLastname] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [phonenumber, setPhoneNumber] = useState('');
  const [nationalid, setNationalId] = useState('');
  const [passport, setPassport] = useState('');
  const [gender, setGender] = useState('');
  const [postalAddress, setpostalAddress] = useState('');
  const [residentialAddress, setresidentialAddress] = useState('');
  const [relationship, setRelationship] = useState(props.currentRelationShip);
  const [southAfricanIdCheckError, setSouthAfricanIdCheckError] = useState(null)
  const [isCitizenCheck, setIsCitizenCheck] = useState(false)

  const [duplicateMembers, setDuplicateMembers] = useState(null)
  const [checkingDuplicateMembers, setCheckingDuplicateMembers] = useState(false)
  const [duplicateMemberFound, setDuplicateMemberFound] = useState(false)
  const [memberAdded, setMemberAdded] = useState(false)

  const [ageRanges, setAgeRanges] = useState(null)
  const [, setExtendedMemberPremiumNotFound] = useState(false)

  const formRef = useRef(null);
  const [options, setOptions] = useState([]);

  const fetchDuplicateMembers = async (national_id) => {
    if (!national_id || props.suspendDuplicateIdCheck) {
      addDependant()
      return
    }
    setDuplicateMemberFound(false)
    setCheckingDuplicateMembers(true);
    try {
      const duplicate = find(props.dependants, { national_id: nationalid })
      if (duplicate) {
        setDuplicateMembers({ active: true, local: true, duplicate });
        setDuplicateMemberFound(true)
        setCheckingDuplicateMembers(false);
        return
      }

      const response = await getPolicyDependents(national_id);
      setDuplicateMembers({ ...response.data, local: false });

      if (response.data.active) {
        setDuplicateMemberFound(true)
      } else {
        setDuplicateMemberFound(false)
        addDependant()
      }
    } catch (error) {
      console.error('Error fetching suggestions:', error);
    }
    setCheckingDuplicateMembers(false);
  };




  useEffect(() => {
    if (dateOfBirth) {
      const rangeObject = getAgeRangeObject(dateOfBirth);
      if (rangeObject) {
        setOptions(rangeObject.options);
      } else {
        setOptions([]);
      }
    } else {
      setOptions([]);
    }
    // setSelectedOption('')
  }, [dateOfBirth]);

  useEffect(() => {

    ValidatorForm.addValidationRule('isValidPhoneNumber', value => {
      return value.length === getLocalNumberLength();
    });

    return function cleanup() {
      ValidatorForm.removeValidationRule('isValidPhoneNumber');
    };
  }, [])


  useEffect(() => {
    ValidatorForm.addValidationRule('isValidNationalId', (value) => {
      if (['Child', 'Spouse', 'Parent', 'Parent_In_Law', 'Extended'].includes(relationship) && value === '') {
        return true;
      }

      if (props.memberRelationshipType === 'extended_members' && value === '') {
        return true
      }

      const partnerGuid = getPartnerGuid();
      if (partnerGuid === "aspinplus") {
        const result = isVAlidSouthAfricanNationalId(value);
        if (result.isValid) {
          setDateOfBirth(formatDate(new Date(result.dob)));
          setGender(result.gender);
          setSouthAfricanIdCheckError(result.error);
          return true;
        } else {
          setSouthAfricanIdCheckError(result.error);
          setGender("");
          setDateOfBirth("");
          return false;
        }
      } else {
        const result = isVAlidBotswanaNationalId(value);
        if (result.isValid) {
          setGender(result.gender);
          setSouthAfricanIdCheckError(result.error);
          return true;
        } else {
          setSouthAfricanIdCheckError(result.error);
          setGender("");
          return false;
        }
      }
    });

    ValidatorForm.addValidationRule('isNationalIdRequired', (value) => {
      if (relationship === 'Child' && value === '') {
        return true;
      }
      return false;
    });




    ValidatorForm.addValidationRule('isValidDateOfBirth', (dobValue) => {
      if (!dateOfBirth) return true;
      const memberConfig = props.getMemberConfig(relationship)
      if (!memberConfig) return true

      const maximum_age = memberConfig.age_range.max
      const minimum_age = memberConfig.age_range.min

      const selectedDateObj = new Date(dobValue);
      const currentDate = new Date();

      const ageDifference = currentDate.getFullYear() - selectedDateObj.getFullYear();

      setAgeRanges({ minimum_age, maximum_age })
      return ageDifference >= minimum_age && ageDifference <= maximum_age

    });

    ValidatorForm.addValidationRule('hasMaximumMemberCount', (relationship) => {
      const memberConfig = props.getMemberConfig(relationship)
      if (!memberConfig) return true

      const maximumMembersAllowed = memberConfig.max_count
      const totalMemberTypeAdded = props.countRelationships(relationship)

      if (totalMemberTypeAdded === maximumMembersAllowed) return false
      return true
    });


    ValidatorForm.addValidationRule('shouldAddPrincipalFirst', (value) => {
      const principalNotAdded = !find(props.dependants, { relationship: "Principal" })
      if (value !== "Principal" && principalNotAdded) {
        return false
      } else {
        return true
      }

    });

    return function cleanup() {
      ValidatorForm.removeValidationRule('isValidDateOfBirth');
      ValidatorForm.removeValidationRule('isValidNationalId');
      ValidatorForm.removeValidationRule('isNationalIdRequired');
      ValidatorForm.removeValidationRule('shouldAddPrincipalFirst');
      ValidatorForm.removeValidationRule('hasMaximumMemberCount');
    };

  }, [relationship, dateOfBirth])


  useEffect(() => {

    const startingIndex = internationalCallingCode.length
    const endingIndex = props.principal.msisdn.length
    const phone = props.principal.msisdn.substring(startingIndex, endingIndex)

    if (props.autoFillPrincipal) {
      setFirstname(props.principal.first_name);
      setMiddleName('');
      setLastname(props.principal.surname);
      setDateOfBirth(props.principal.date_of_birth);
      setPhoneNumber(phone);
      setNationalId(props.principal.national_id);
      setGender(props.principal.gender);
      setpostalAddress('');
      setresidentialAddress(props.principal.address || '');
      // setRelationship('Principal');
      props.toggleAutoFillPrincipal()
    }
  }, [props.autoFillPrincipal]);



  const triggerFieldValidation = (fieldName) => {
    if (formRef.current) {
      const field = getFormField(formRef.current.childs, fieldName)
      if (field) field.validate();
    }
  };

  const getFormField = (fields, name) => {
    for (let field of fields) {
      if (field.props.name === name) return field
    }
    return null
  }

  const handleOptionChange = (event) => {
    props.setSelectedExtendedBenefit(event.target.value)
  };

  const handleIsCitizenChange = (event) => {
    setIsCitizenCheck(event.target.checked);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'firstname') {
      setFirstname(value)
    }
    if (name === 'middlename') {
      setMiddleName(value)
    }
    if (name === 'lastname') {
      setLastname(value)
    }
    if (name === 'dateOfBirth') {
      setDateOfBirth(value)
      setExtendedMemberPremiumNotFound(false)
    }
    if (name === 'phonenumber') {
      setPhoneNumber(value)
    }
    if (name === 'nationalid') {
      setNationalId(value)
    }
    if (name === 'gender') {
      setGender(value)
    }
    if (name === 'postalAddress') {
      setpostalAddress(value)
    }
    if (name === 'residentialAddress') {
      setresidentialAddress(value)
    }
    if (name === 'relationships') {
      setRelationship(value)
      triggerFieldValidation('nationalid');
      setDateOfBirth('')
      setNationalId('')
    }
    if (name === 'passport') {
      setPassport(value)
    }

  };
  const reset = () => {
    setFirstname('');
    setMiddleName('');
    setLastname('');
    setDateOfBirth('');
    setPhoneNumber('');
    setNationalId('');
    setGender('');
    setpostalAddress('');
    setresidentialAddress('');
    setRelationship('');
    props.setLastSelectedExtendedRelationship(null)
  }

  const getAge = (dateOfBirth) => {
    if (!dateOfBirth) return ""
    const age = moment().diff(dateOfBirth, 'years');
    return age;
  }

  const getNuclearBenefits = (relationship) => {
    console.log('getNuclearBenefits: ', relationship);
    const nukPremium = getNuclearPremium(props.selectedPlan, props.coverType)

    const nukBenefit = getNuclearBenefit(props.selectedPlan)
    const accidentalBenefitAmount = 20000 * 100
    console.log('nukPremium: ', nukPremium, 'nukBenefit: ', nukBenefit, 'accidentalBenefitAmount: ', accidentalBenefitAmount, props.selectedPlan);

    let benefits = []

    switch (relationship) {
      case 'Principal':
        const principal_uuid_funeral = uuidv4();
        const principal_uuid_accidental = uuidv4();
        benefits = [
          {
            type: "FUNERAL BENEFIT",
            cover_amount_in_cents: nukBenefit,
            waiting_period_in_days: 30,
            cover_usage_count: 0,
            benefit_period_cardinality: 1,
            benefit_period_granuality: "year",
            premium_amount: nukPremium,
            uuid: principal_uuid_funeral,
            is_initialized: true,
            additional_details: {
              premium_amount: nukPremium,
              uuid: principal_uuid_funeral,
            }
          },
          {
            type: "ACCIDENTAL BENEFIT",
            cover_amount_in_cents: nukBenefit > accidentalBenefitAmount ? accidentalBenefitAmount : nukBenefit,
            waiting_period_in_days: 30,
            cover_usage_count: 0,
            benefit_period_cardinality: 1,
            is_initialized: true,
            benefit_period_granuality: "year",
            premium_amount: 0,
            uuid: principal_uuid_accidental,
            additional_details: {
              premium_amount: 0,
              uuid: principal_uuid_accidental,
            }
          }
        ]
        break;

      case 'Spouse':
        console.log('Spouse: ', relationship);
        const spouse_uuid_accidental = uuidv4();
        const spouse_uuid_funeral = uuidv4();
        benefits = [
          {
            type: "FUNERAL BENEFIT",
            cover_amount_in_cents: nukBenefit,
            waiting_period_in_days: 30,
            cover_usage_count: 0,
            benefit_period_cardinality: 1,
            benefit_period_granuality: "year",
            premium_amount: 0,
            uuid: spouse_uuid_funeral,
            is_initialized: true,
            additional_details: {
              premium_amount: 0,
              uuid: spouse_uuid_funeral,
            }
          },
          {
            type: "ACCIDENTAL BENEFIT",
            cover_amount_in_cents: nukBenefit > accidentalBenefitAmount ? accidentalBenefitAmount : nukBenefit,
            waiting_period_in_days: 30,
            cover_usage_count: 0,
            benefit_period_cardinality: 1,
            benefit_period_granuality: "year",
            premium_amount: 0,
            is_initialized: true,
            uuid: spouse_uuid_accidental,
            additional_details: {
              premium_amount: 0,
              uuid: spouse_uuid_accidental,
            }
          }
        ]
        console.log('spouse benefits: ', benefits);
        break;

      case 'Child':
        const child_uuid_funeral = uuidv4();

        benefits = [
          {
            type: "FUNERAL BENEFIT",
            cover_amount_in_cents: nukBenefit,
            waiting_period_in_days: 30,
            cover_usage_count: 0,
            benefit_period_cardinality: 1,
            benefit_period_granuality: "year",
            premium_amount: 0,
            uuid: child_uuid_funeral,
            is_initialized: true,
            additional_details: {
              premium_amount: 0,
              uuid: child_uuid_funeral,
            }
          },
        ]
        break;

      default:
        break;
    }
    return benefits
  }

  const addDependant = () => {

    const internationalCallingCode = getPartner().country.international_calling_code

    const isExtended = isExtendedMember(props.currentRelationShip)

    if (isExtended) {
      const extended_uuid = uuidv4();
      const extended_uuid_funeral = uuidv4();

      const payload = {
        "first_name": firstname,
        "middle_name": middlename,
        "last_name": lastname,
        "msisdn": internationalCallingCode + phonenumber,
        "date_of_birth": dateOfBirth,
        "gender": gender,
        "national_id": nationalid || "N/A",
        "passport": passport || "N/A",
        "postal_address": postalAddress,
        "relationship": relationship,
        "residential_address": residentialAddress,
        "type": 'Extended_2',
        "premium_amount_in_cents": props.selectedExtendedBenefit.premium_amount * 100,
        "extended_premium": 0,
        "add_on_premium": 0,
        "benefits": [
          {
            type: "FUNERAL BENEFIT",
            cover_amount_in_cents: props.selectedExtendedBenefit.benefit_amount * 100,
            waiting_period_in_days: 30,
            cover_usage_count: 0,
            benefit_period_cardinality: 1,
            benefit_period_granuality: "year",
            premium_amount: props.selectedExtendedBenefit.premium_amount * 100,
            uuid: extended_uuid_funeral,
            is_initialized: true,
            additional_details: {
              premium_amount: props.selectedExtendedBenefit.premium_amount * 100,
              uuid: extended_uuid_funeral,
            }

          }
        ],
        uuid: extended_uuid,
        additional_details: {
          uuid: extended_uuid,
          memberType: 'Extended',
        }

      }
      props.createDependant(payload);
      const tempDependants = props.dependants;
      tempDependants.push(payload);
      props.setDependants(tempDependants);
      setMemberAdded(true)
      reset();
      return
    }
    const memberType = props.getMemberType(relationship);
    const extendedConfig = memberType === 'Extended' ? props.getExtendedMemberConfig(dateOfBirth) : null
    const extendedPremium = extendedConfig ? extendedConfig.premium_rate : 0
    const extendedBenefit = extendedConfig ? extendedConfig.benefit_amount : 0

    if (memberType === 'Extended' && extendedPremium === 0) {
      setExtendedMemberPremiumNotFound(true)
      return
    } else {
      setExtendedMemberPremiumNotFound(false)
    }

    const parent_uuid_funeral = uuidv4();
    const extendedMemberBenefit = memberType === 'Extended' ? [
      {
        type: "FUNERAL BENEFIT",
        cover_amount_in_cents: extendedBenefit,
        waiting_period_in_days: 30,
        cover_usage_count: 0,
        benefit_period_cardinality: 1,
        benefit_period_granuality: "year",
        premium_amount: extendedPremium,
        is_initialized: true,
        uuid: parent_uuid_funeral,
        additional_details: {
          premium_amount: 0,
          uuid: parent_uuid_funeral,
        }

      }] : []

    const nuclearInitialBenefits = getNuclearBenefits(relationship);
    const extendedInitialBenefits = extendedMemberBenefit;

    const allbenefits = memberType === 'Extended' ? extendedInitialBenefits : nuclearInitialBenefits
    const totalPremium = calculateTotalPremiumInCents(allbenefits)

    const member_uuid = uuidv4();
    const payload = {
      "first_name": firstname,
      "middle_name": middlename,
      "last_name": lastname,
      "msisdn": internationalCallingCode + phonenumber,
      "date_of_birth": dateOfBirth,
      "gender": gender,
      "national_id": nationalid || "N/A",
      "passport": passport || "N/A",
      "postal_address": postalAddress,
      "relationship": relationship,
      "residential_address": residentialAddress,
      "type": memberType,
      "premium_amount_in_cents": totalPremium,
      "allbenefits": allbenefits,
      "benefits": [...allbenefits],
      uuid: member_uuid,
      additional_details: {
        uuid: member_uuid,
        memberType: 'Nuclear',
      }

    }
    console.log('payload: ', payload);
    const tempDependants = props.dependants;
    tempDependants.push(payload);
    const replicated = replicatePrincipalBenefits(tempDependants)
    props.setDependants(replicated);
    props.createDependant(payload);
    setMemberAdded(true)
    reset();

    posthog.capture('add_dependant_monamodi', {
      email: `${getUserEmail()}`,
      name: `${getUserFullname()}`,
      partner: `${getPartnerGuid()}`,
      timestamp: new Date().toLocaleString(),
    });


  }


  const handleDependantCreatedSuccessfully = () => {
    setMemberAdded(false)
    posthog.capture('add_dependant_success_monamodi', {
      email: `${getUserEmail()}`,
      name: `${getUserFullname()}`,
      partner: `${getPartnerGuid()}`,
      timestamp: new Date().toLocaleString(),
    });
  }

  //country calling code
  const internationalCallingCode = getPartner().country.international_calling_code

  const minimum_age = ageRanges ? ageRanges.minimum_age : ''
  const maximum_age = ageRanges ? ageRanges.maximum_age : ''
  const dobErrorMessage = `Age ranges for the selected relationship are:  ${minimum_age} to ${maximum_age}, current age is ${getAge(dateOfBirth)} years`

  const isExtended = isExtendedMember(props.currentRelationShip)

  return (
    <>

      <AlertDialog
        custom
        show={checkingDuplicateMembers}
        size="sm"
        style={{ marginTop: '0', top: '30vh' }}
        confirmBtnText={'ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'cancel'}
        showTitle={false}
        confirmBtnCssClass
      >
        <CircularProgress />
        <h2> Checking for duplicate member with this ID....</h2>
      </AlertDialog>


      <AlertDialog
        show={duplicateMemberFound}
        danger
        title={'member is already registered'}
        onConfirm={() => setDuplicateMemberFound(false)}
        confirmBtnText={'Try again'}
        confirmBtnCssClass
        showCancel={false}
        style={{ marginTop: '0', top: '30vh' }}
      >

        {
          duplicateMembers && duplicateMembers.local &&
          <>
            <p>We found another member with same ID {nationalid} on this quotation.</p>
            <Button onClick={() => {
              props.viewMembers()
              setDuplicateMembers(null)
              setDuplicateMemberFound(false)
            }}> view members</Button>
          </>


        }
        {
          duplicateMembers &&
          duplicateMembers.policy &&
          duplicateMembers.policy.policy_number && <p>We found another member with same ID {nationalid} on an existing policy {duplicateMembers.policy.policy_number}</p>
        }
        {/* 
          <button
            onClick={() => {
              const url = `admin/policy-processing/${duplicateMembers.policy.policy_number}`;
              window.open(url, '_blank');
              // hashHistory.push(`admin/policy-processing/${duplicateMembers.policy.policy_number}`)
            }
            }
          >view</button> */}
      </AlertDialog>

      <AlertDialog
        success
        show={memberAdded}
        size="sm"
        title={'member added'}
        style={{ marginTop: '0', top: '30vh' }}
        onConfirm={() => { handleDependantCreatedSuccessfully() }}
        onCancel={() => { handleDependantCreatedSuccessfully() }}
        confirmBtnText={'Ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'cancel'}
        confirmBtnCssClass
      >
        <div >
          <Button variant="contained" onClick={() => {
            handleDependantCreatedSuccessfully()
            props.viewMembers()
            setDuplicateMembers(null)
            setDuplicateMemberFound(false)
          }} style={{ background: 'green', }}>view members</Button>
        </div>
      </AlertDialog>

      <AlertDialog
        info
        show={props.depAlert}
        size="sm"
        title={'member  removed'}
        style={{ marginTop: '0', top: '30vh' }}
        onConfirm={() => { props.setDepAlert(false) }}
        confirmBtnText={'ok'}
        showCancel={false}
        cancelBtnText={'cancel'}
        confirmBtnCssClass
      >
      </AlertDialog>

      {/* <AlertDialog
        show={props.showAddDependantErrorAlert}
        danger
        title={'Error creating member'}
        onConfirm={() => handleDependantCreateError()}
        confirmBtnText={'Try again'}
        confirmBtnCssClass
        showCancel={false}
        style={{ marginTop: '0', top: '30vh' }}
      >
        {'error'}
      </AlertDialog> */}

      <ValidatorForm onSubmit={() => fetchDuplicateMembers(nationalid)} ref={formRef} >
        <section className={classes.quoteinputwrapper}>

          <Grid container spacing={2} >
            <Grid item xs={12} sm={12} md={12}>
              <div className={classes.detailstitle}>
                {
                  props.currentRelationShip === 'Principal'
                    ? <h3>Lets add Main member first</h3>
                    : <h3>Add a {props.currentRelationShip} member</h3>

                }
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <div className={classes.checkconditionCitizenship}>
                <FormGroup aria-label="position" row>
                  <FormControlLabel
                    checked={isCitizenCheck}
                    onChange={handleIsCitizenChange}
                    control={<Checkbox size="small" />}
                    label="Is Non Citizen?"
                    labelPlacement="start"
                  />
                </FormGroup>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={2} >
            <Grid item xs={12} sm={4} md={4}>
              <TextValidator
                id="First-Name"
                fullWidth
                label="First Name"
                name='firstname'
                value={firstname}
                onChange={handleChange}
                className={classes.quoteinputs}
                variant="outlined"
                validators={['required']}
                errorMessages={['Please enter first name']}
                style={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <TextValidator
                id="Middle-Name"
                fullWidth
                label="Middle Name"
                name='middlename'
                value={middlename}
                onChange={handleChange}
                className={classes.quoteinputs}
                variant="outlined"
                style={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <TextValidator
                id="Last-Name"
                fullWidth
                label="Last Name"
                name='lastname'
                value={lastname}
                onChange={handleChange}
                className={classes.quoteinputs}
                variant="outlined"
                validators={['required']}
                errorMessages={['Please enter Last name']}
                style={{ width: "100%" }}
              />
            </Grid>

            <Grid item xs={12} sm={4} md={4}>
              {
                isCitizenCheck ? (<>

                  <TextValidator
                    name='passport'
                    id="passport"
                    label="Passport No"
                    value={passport}
                    onChange={handleChange}
                    fullWidth
                    variant="outlined"
                    validators={['required']}
                    errorMessages={['Please enter a valid passport number']}
                    style={{ width: "100%" }}
                  />
                </>) : (<>

                  <TextValidator
                    id="National Id"
                    fullWidth
                    type='number'
                    label={getPartnerGuid() === 'aspinplus' ? 'ID Number' : "Omang Number"}
                    name='nationalid'
                    value={nationalid}
                    onChange={handleChange}
                    className={classes.quoteinputs} 
                    variant="outlined"
                    validators={
                     ( relationship === 'Child' || relationship === 'Spouse')
                        ? ['isValidNationalId']
                        : props.memberRelationshipType === 'parent_members'
                          ? []
                          : props.memberRelationshipType === 'extended_members'
                            ? []
                            : ['required', 'isValidNationalId']
                    }

                    errorMessages={['Please enter a valid national id', southAfricanIdCheckError ? southAfricanIdCheckError : 'Invalid national Id']}
                    style={{ width: "100%" }}
                  />
                </>)
              }

            </Grid>

            <Grid item xs={12} sm={4} md={4}>

              <SelectValidator
                labelId="select-gender"
                id="select-gender"
                value={gender}
                name='gender'
                type="text"
                label="Gender"
                onChange={handleChange}
                variant="outlined"
                validators={['required']}
                errorMessages={['Please select gender']}
                style={{ width: "100%" }}
                sx={{
                  fontFamily: "Open Sans",
                  fontStyle: "normal",
                  fontWeight: 300,
                  fontSize: "12px",
                  lineHeight: "16px",
                  letterSpacing: "0.005em",
                  color: "#000000",
                }}
              >
                <MenuItem sx={{
                  fontFamily: "Open Sans",
                  fontStyle: "normal",
                  fontWeight: 300,
                  fontSize: "12px",
                  lineHeight: "16px",
                  letterSpacing: "0.005em",
                  color: "#000000",
                }} value="Male">Male</MenuItem>
                <MenuItem sx={{
                  fontFamily: "Open Sans",
                  fontStyle: "normal",
                  fontWeight: 300,
                  fontSize: "12px",
                  lineHeight: "16px",
                  letterSpacing: "0.005em",
                  color: "#000000",
                }} value="Female">Female</MenuItem>

              </SelectValidator>
            </Grid>

            <Grid item xs={12} sm={4} md={4}>
              <TextValidator
                id="Date-of-birth"
                label="Date of birth"
                name='dateOfBirth'
                value={dateOfBirth}
                onChange={handleChange}
                fullWidth
                type='date'
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                validators={["required", "isValidDateOfBirth"]}
                errorMessages={["Date of birth is required", dobErrorMessage]}
                style={{ width: "100%" }}
              />


            </Grid>

            <Grid item xs={12} sm={4} md={4}>
              <TextValidator
                id="Phone"
                fullWidth
                label="Phone Number"
                name='phonenumber'
                value={phonenumber}
                onChange={handleChange}
                type="number"
                className={classes.quoteinputs}
                InputProps={{
                  startAdornment: <InputAdornment
                    sx={{
                      paddingTop: "32px",
                    }}
                    position="start"
                  >{internationalCallingCode ? internationalCallingCode : ''}</InputAdornment>,
                  style: {
                    background: "#F7F7F7"
                  }
                }}
                variant="outlined"
                validators={phonenumber ? ["isValidPhoneNumber"] : []}
                errorMessages={[`Please enter a valid ${getLocalNumberLength()} digit phone number`]}
                style={{ width: "100%" }}

              />
            </Grid>

            <Grid item xs={12} sm={4} md={4}>
              <TextValidator
                id="postal-address"
                fullWidth
                label="Postal Address"
                name='postalAddress'
                value={postalAddress}
                onChange={handleChange}
                className={classes.quoteinputs}
                variant="outlined"
                style={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <TextValidator
                id="residential-address"
                fullWidth
                label="Residential Address"
                name='residentialAddress'
                value={residentialAddress}
                onChange={handleChange}
                className={classes.quoteinputs}
                variant="outlined"
                style={{ width: "100%" }}
              />
            </Grid>

            {isExtended && <Grid item xs={12}>
              <SelectValidator
                value={props.selectedExtendedBenefit}
                label='Extended Options'
                id="extended-options"
                name='extendedOptions'
                onChange={handleOptionChange} // Use the custom handler
                className="form-control"
                type="text"
                variant="outlined"
                validators={['required']} // Add other validators as needed
                errorMessages={['Please select an option']}
                fullWidth
                style={{ width: '100%' }}
              >
                <MenuItem value="">{'Please select an benefit'}</MenuItem>
                {(props.selectedExtendedBenefit ? [props.selectedExtendedBenefit] : options).map((option) => (
                  <MenuItem key={option.benefit_amount} value={option}>
                    {`Benefit : ${props.currencyCode} ${option.benefit_amount}, Premium : ${props.currencyCode} ${option.premium_amount}`}
                  </MenuItem>
                ))}
              </SelectValidator>
            </Grid>}
            {isExtended && (!dateOfBirth && !props.selectedExtendedBenefit) && <p style={{ color: 'red', margin: '20px' }}>please select date first for benefits to appear</p>}

          </Grid>
        </section>


        <section className={classes.benefitswrapper}>



          <hr />


          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <div className={classes.quotesbtns}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  type='submit'
                //onClick={addDependant}
                >
                  Add Member
                </Button>

                <Button variant="contained" onClick={() => {
                  props.viewMembers()
                }} style={{ background: 'green', }}>Cancel</Button>

              </div>
            </Grid>
          </Grid>
        </section>
      </ValidatorForm >
    </>
  )
}

export default Adddependant