import React from 'react'
import ModalInc from '../../../ModalInc'
import { Grid, MenuItem } from '@mui/material'
import ButtonInc from '../../../ButtonInc'
import { SelectValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { getPartnerGuid } from '../../../../lib/access.es6';
import { funeralPopUpPaymentPartnerList, PAYMENT_METHODS } from '../../../../lib/constants';

const InitiatePayment = (props) => {
  const [premium, setPremium] = React.useState('')
  if (props.popupLoader) {
    return "loading premiums....."
  }
  const handleChange = (event) => {
    const { name, value } = event.target
    if (name === "selectPremium") {
      setPremium(value)
    }
  }
  const handleSubmit = () => {
    const partnerGuid = getPartnerGuid();
    const payload = {
      policy_guid: props.policyGUid,
      amount_in_cents: premium,
      partner: partnerGuid,
      customer_guid: props.customerGuid,
      type: PAYMENT_METHODS.STRIKE_MONEY,
    };

    console.log("payload", payload)
    if (partnerGuid === 'atlehangguid') {
      payload.type = PAYMENT_METHODS.BANK_CARD;
    }

    if (partnerGuid === 'MMI_Ghana') {
      payload.type = PAYMENT_METHODS.RECURRING_DEBIT_ORDER;
    }
    props.initiatePaymentMobile(payload)
  }
  const getInitiateMoneyDialogTitle = () => {

    const partnerGuid = getPartnerGuid();

    if (funeralPopUpPaymentPartnerList.includes(partnerGuid)) {
      return {
        title: "Card Payment Initiated",
        message: "A card payment has been requested, Customer will receive an SMS with a link to enter their card payment details to process payment.",
      };
    }
    return {
      title: "Mobile Money Collection",
      message: "A mobile money payment has been initiated, the customer will be prompted to enter their pin to proceed with the transaction.",
    };
  }

  const handleCloseInitiatePayment = () => {
    props.closeMobileMoneyConfirmation();
    props.toggleInitiatePayment()
  }
  return (
    <>
      <ModalInc
        modalTitle="Initiate Payment "
        subTitle="Fill the below fields to initiate payment for this policy"
        open={props.showInitiatePaymentPopup}
        onClose={props.toggleInitiatePayment}
        fullWidth
      >

        <ValidatorForm onSubmit={() => handleSubmit()} >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <SelectValidator
                labelId="select-premium"
                id="select-premium"
                value={premium}
                name='selectPremium'
                label="Select  Premium"
                onChange={handleChange}
                variant="outlined"
                validators={['required']}
                errorMessages={['Please select premium']}
                style={{ width: "100%" }}
                sx={{
                  fontFamily: "Open Sans",
                  fontStyle: "normal",
                  fontWeight: 300,
                  fontSize: "12px",
                  lineHeight: "16px",
                  letterSpacing: "0.005em",
                  color: "#000000",
                }}
              >

                {
                  props.productPremium.filter(x => x.payment_method === 'Mobile_Money').map((premium, index) => (
                    <MenuItem
                      key={index}
                      sx={{
                        fontFamily: "Open Sans",
                        fontStyle: "normal",
                        fontWeight: 300,
                        fontSize: "12px",
                        lineHeight: "16px",
                        letterSpacing: "0.005em",
                        color: "#000000",
                      }}
                      value={premium.amount_in_cents}>
                      {' '}
                      {`${premium.cardinality} ${premium.granularity
                        } ${premium.amount_in_cents / 100}`}{' '}
                    </MenuItem>
                  ))
                }



              </SelectValidator>
            </Grid>

          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={7} >
              <ButtonInc
                variant="contained"
                color="primary"
                size="large"
                type='submit'
                style={{ marginTop: "20px", float: "right" }}
              >
                Initiate Payment
              </ButtonInc>
            </Grid>
            <Grid item xs={5}>
              <ButtonInc
                variant="contained"
                color="primary"
                size="large"
                style={{ marginTop: "20px" }}
                onClick={props.toggleInitiatePayment}
              >
                Cancel
              </ButtonInc>
            </Grid>
          </Grid>



        </ValidatorForm>


      </ModalInc>

      <ModalInc
        modalTitle={getInitiateMoneyDialogTitle().title}
        open={!!props.mobileMoneyTransaction || !!(props.policyData && props.policyData.mobileMoneyTransactionErrors)}
        onClose={() => handleCloseInitiatePayment()}
        fullWidth
      >
        <p>{getInitiateMoneyDialogTitle().message}</p>

        <Grid container spacing={2}>
          <Grid item xs={6} >
            {/* <ButtonInc
                variant="contained"
                color="primary"
                size="large"
                type='submit'
                style={{ marginTop: "20px", float: "right" }}
              >
                Buy Policy
              </ButtonInc> */}
          </Grid>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <ButtonInc
              variant="contained"
              color="primary"
              size="large"

              onClick={() => handleCloseInitiatePayment()}
            >
              Cancel
            </ButtonInc>
          </Grid>
        </Grid>
      </ModalInc>

    </>
  )
}

export default InitiatePayment